<template>
    <van-popup v-model="show" position="bottom" safe-area-inset-bottom class="pop-gift">
        <div class="g-head">
            <img src="https://img.chaolu.com.cn/ACT/double11-2024/main/gift1.png" class="logo" />
            <img src="https://img.chaolu.com.cn/ACT/anniversary-marketing-2024/close.png" class="x"
                @click="show = false" />
        </div>
        <div class="g-con">
            <div class="puhui g-title">送TA一份「限量好礼」</div>
            <div class="g-tab">
                <div class="g-tab-model ellipsis" :class="{ act: index == acIndex }" v-for="(titem, index) in list"
                    @click="acIndex = index">
                    {{ titem.purchaseInstructions }}
                </div>
            </div>
            <div class="g-model">
                <div class="gm-pic">
                    <img :src="item.goodsImg" v-if="item.goodsImg" />
                </div>
                <div class="gm-info">
                    <div class="gm-title puhui ellipsis">{{ item.goodsName }}</div>
                    <div class="gm-add">
                        <img src="https://img.chaolu.com.cn/ACT/double11-2024/main/add.png" class="gm-img" />
                        <div class="gm-name">{{ item.goodsIntroduction }}</div>
                    </div>
                    <div class="gm-price BebasNeueBold">
                        <span class="dl">¥</span>
                        <span class="price">{{ item.salePrice }}</span>
                        <span class="ori-price" v-if="item.originPrice">¥{{ item.originPrice }}</span>
                    </div>

                </div>

            </div>
            <div class="gm-sub puhui" @click="buyCard(item)">{{ buttonText(item) }}</div>
        </div>
    </van-popup>
</template>
<script>
import buyStatus from '../config'
export default {
    data() {
        return {
            show: false,
            acIndex: 0
        }
    },
    props: {
        list: {
            type: Array
        },
        activityStatus: {
            type: String
        },
        goodsStatusMap: {
            type: Object
        },
    },
    computed: {
        item() {
            return this.list && this.list.length ? this.list[this.acIndex] : {}
        }
    },
    created() {
        // this.getGoodStatus();
    },
    methods: {
        buttonText(item) {
            if (buyStatus[this.goodsStatusMap[item.id]]) {
                let name = buyStatus[this.goodsStatusMap[item.id]].name;
                return name
            }
            return this.activityStatus === 'notin' ? '待开启' : this.activityStatus === 'end' ? '已结束' : '马上抢'
        },
        buyCard(item) {
            this.show = false;
            this.countPoint('410', item.id, '40065')
            this.$emit('buyCard', item, 'must_buy')
        },
        showFun() {
            this.acIndex = 0;
            this.show = true;
        }
    }
};
</script>
<style lang="less">
.pop-gift {

    overflow: visible;
    background: #fff;
    border-radius: 24px 24px 0px 0px;

    div {
        box-sizing: border-box;
    }

    img {
        width: 100%;
        vertical-align: top;
    }

    .g-head {
        height: 200px;
        background: linear-gradient(0deg, #FFF 0%, #FFF5DD 100%);
        position: relative;
        border-radius: 24px 24px 0px 0px;

        .logo {
            width: 432px;
            height: 280px;
            position: absolute;
            left: 50%;
            bottom: 63px;
            transform: translateX(-50%);
        }

        .x {
            width: 44px;
            height: 44px;
            position: absolute;
            right: 32px;
            top: 42px;
        }
    }

    .g-title {
        text-align: center;
        font-size: 52px;
        color: #000000;
        line-height: 1;
        position: relative;
        margin-top: -10px;
        margin-bottom: 47px;
    }

    .g-tab {
        display: flex;
        justify-content: space-between;
        margin: 0 32px 90px;

        .g-tab-model {
            width: 220px;
            padding: 0 10px;
            height: 72px;
            background: #FFFFFF;
            border-radius: 10px;
            border: 2px solid #DFDFDF;
            font-size: 26px;
            color: #000000;
            text-align: center;
            line-height: 70px;

            &.act {
                background: linear-gradient(0deg, #FFDC38 0%, #FFF587 100%);
                border: 2px solid #000000;
                font-weight: bold;
            }
        }
    }

    .g-model {

        background: #FFFFFF;
        margin: 0 44px;
        display: flex;
        align-items: center;

        .gm-pic {
            width: 280px;
            height: 250px;
            margin-right: 51px;
            flex-shrink: 0;

            img {
                height: 100%;
                object-fit: cover;
            }
        }

        .gm-info {
            flex: 1;
        }

        .gm-title {
            font-size: 40px;
            color: #000000;
            line-height: 1;
        }

        .gm-add {
            display: flex;
            align-items: center;
            margin: 34px 0 24px;
            position: relative;
            z-index: 8;

            .gm-img {
                width: 40px;
                height: 40px;
                margin-right: 12px;
            }
        }

        .gm-name {
            font-weight: 600;
            font-size: 36px;
            color: #000000;
            line-height: 1;
        }

        .gm-price {
            margin: 0 0 6px;

            .dl {
                color: #EE4832;
                margin-right: 4px;
                font-size: 44px;
            }

            .price {
                color: #EE4832;
                margin-right: 9px;
                position: relative;
                top: 2px;
                font-size: 76px;
            }

            .ori-price {
                text-decoration: line-through;
                color: #666;
                font-size: 44px;
            }
        }
    }

    .gm-sub {
        padding-top: 50px;
        line-height: 1px;
        background: url(https://img.chaolu.com.cn/ACT/double11-2024/main/giftsub.png);
        background-size: 100% 100%;
        font-size: 52px;
        color: #FFFFFF;
        text-align: center;
        width: 536px;
        height: 124px;
        margin: 90px auto;
    }
}
</style>