<template>
    <van-popup v-model="showMyDeposit" position="bottom" safe-area-inset-bottom class="popmyeposit">
        <div class="pop-title">
            <span class="ts">我的定金</span>
            <van-icon name="cross" class="pop-close" color="#fff" @click="showMyDeposit = false" />
        </div>
        <div class="tips" v-if="hasData">
            {{ $parent.activityStatus == 'notin'?'11月11日 10:00 开启尾款支付通道':'11月15日 24:00 关闭尾款支付通道' }}
        </div>
        <div class="pop-box">
            <template v-if="hasData">
                <van-list v-model="loading" :finished="finished" @load="loadList" :immediate-check="false" :offset="50"
                    finished-text="没有更多啦~">
                    <div class="pop-model" v-for="item in dataList">
                        <div class="pop-info-name">{{ item.goodsName }} (定金)</div>
                        <div class="pop-name-line">
                            <div class="pop-pre">提前享{{ item.preGoodsName }}</div>
                            <div class="pop-price">已支付定金：<span class="pf">￥{{ item.preGoodsPrice }}</span></div>
                        </div>
                        <!-- <div class="pop-use">提前享：{{ item.preGoodsName }}</div> -->
                        <div class="pop-pay-line">
                            <div class="pop-time">{{ item.createTime }}</div>
                            <div class="">{{item.payStatus==3?'已':'待'}}支付尾款：<span class="pf c-r">￥{{ item.afterGoodsPrice }}</span></div>
                        </div>
                        <div class="pop-recommend-from">
                            <div class="tt"><span v-if="item.bindName">来自{{ item.bindName }}的推荐</span></div>
                            <div class="sub" :class="{before:item.status=='NOT_STARTED'}" @click="pay(item)" v-if="item.payStatus!=3" >付尾款</div>
                        </div>
                    </div>
                </van-list>
            </template>
            <div class="nulldata" v-else>
                <img src="https://chaolu.oss-cn-hangzhou.aliyuncs.com/ACT/anniversary-2024/index/null.png" />
                <div class="nulltip">暂无定金记录</div>
            </div>
        </div>
    </van-popup>
</template>
<script>
import userMixin from '@/mixin/userMixin';
export default {
    mixins: [userMixin],
    data() {
        return {
            showMyDeposit: false,
            dataList: [],
            pageNum: 1,
            finished: false,
            loading: false,
            hasData: true,
            payStatus: {
                // 1:已支付定金未支付尾款,
                // 2待支付尾款,
                // 3已支付尾款
            }
        }
    },
    methods: {
        pay(item) {
            if(item.status!='CAN_BUY'){
                this.$toast('未到尾款支付时间')
                return;
            }
            this.showMyDeposit= false;
            this.$emit('pay',{...item.goodsInfo,prePayOrderNo:item.prePayOrderNo})
        },
        loadList() {
            console.log('load')
            this.pageNum += 1;
            this.getData();
        },
        getData(refresh) {
            const size = 10;
            this.$axios.post(this.baseURLApp + "/perUseGoods/getPreUseGoodsRecord", {
                hasWx: true,
                userId: this.userId,
                page: this.pageNum,
                size
            }).then((res) => {
                const d = res.data.records || [];
                if (!d.length && this.dataList.length == 0) {
                    this.hasData = false;
                } else {
                    this.hasData = true;
                }
                if (refresh) {
                    this.dataList = d;
                } else {
                    this.dataList.push(...d);
                }
                this.finished = d.length < size;
                this.loading = false;
            })
        },
        show() {
            this.loading = true;
            this.pageNum = 1;
            this.dataList = [];
            this.getData(true);
            this.showMyDeposit = true;
        }
    }
}
</script>
<style scoped lang="less">
.popmyeposit {
    border-radius: 24px 24px 0px 0px;
    background: #F5F5F5;

    div {
        box-sizing: border-box;
    }

    img {
        vertical-align: top;
        width: 100%;
    }

    .pop-title {
        color: #242831;
        font-weight: bold;
        font-size: 32px;
        text-align: center;
        height: 116px;
        position: relative;
        display: flex;
        align-items: center;
        justify-content: center;

        .ts {
            position: relative;
            transform-style: preserve-3d;
            display: inline-block;

            &::after {
                content: '';
                position: absolute;
                left: -10px;
                bottom: -5px;
                width: 150px;
                height: 18px;
                background: linear-gradient(90deg, #FF6945 0%, rgba(255, 232, 206, 0) 100%);
                border-radius: 38px;
                transform: translateZ(-10px);
                z-index: -1;
            }
        }

        .pop-close {
            position: absolute;
            right: 32px;
            top: 38px;
            width: 44px;
            height: 44px;
            line-height: 44px;
            border-radius: 50%;
            background: rgba(39, 40, 49, 0.2);
            font-size: 28px;
            text-align: center;
        }
    }

    .tips {
        font-weight: 600;
        font-size: 24px;
        color: #EE4832;
        line-height: 44px;
        text-align: center;
        margin-bottom: 10px;
    }

    .nulldata {
        height: 100%;
        padding-top: 181px;

        img {
            width: 320px;
            height: 320px;
            display: block;
            margin: 0 auto;
        }

        .nulltip {
            font-size: 28px;
            color: #6C727A;
            line-height: 1;
            margin-top: 21px;
            text-align: center;
        }
    }

    .pop-box {
        height: 800px;
        overflow: auto
    }

    .pop-model {
        // height: 192px;
        background: #FFFFFF;
        border-radius: 14px;
        padding: 0 32px;
        margin: 20px 32px 0;
        color: #242831;

        .pop-info-name {
            font-size: 28px;
            line-height: 1;
            font-weight: bold;
            padding: 36px 0 22px;
        }

        .pop-name-line {
            display: flex;
            justify-content: space-between;
            align-items: center;
            padding: 0 0 18px;

            .pop-pre {
                height: 39px;
                background: #FFF1CD;
                border-radius: 4px;
                padding: 0 12px;
                font-weight: 600;
                font-size: 22px;
                color: #592E00;
                text-align: center;
                line-height: 1;
                display: flex;
                align-items: center;
            }

            .pop-price {
                font-size: 22px;
            }
        }

        .pf {
            font-weight: bold;
            font-size: 28px
        }

        .c-r {
            color: #EE4832;
        }

        .pop-pay-line {
            display: flex;
            justify-content: space-between;
            font-size: 22px;
            align-items: center;
            padding-bottom: 24px;

            .pop-time {
                font-size: 22px;
            }
        }

        .pop-recommend-from {
            display: flex;
            justify-content: space-between;
            height: 108px;
            align-items: center;
            border-top: 1px solid #eee;

            .tt {
                color: #010101;
                font-size: 22px;
            }

            .sub {
                width: 200px;
                line-height: 60px;
                background: linear-gradient(0deg, #F35947 0%, #FBA27B 100%);
                border-radius: 30px;
                font-weight: 500;
                font-size: 24px;
                color: #fff;
                text-align: center;
            }
            .before{
                background: linear-gradient(0deg,#BABABA  0%,#DDDDDD  100%);
                color: #555;
            }
        }
    }
}
</style>