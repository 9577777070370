<template>
  <div class="">
    <headBar ref="headerHeight" title="运动币商城" left-arrow @click-left="newAppBack"></headBar>
    <div class="bg">
      <div class="icon">
        <p class="l1">我的运动币</p>
        <p class="l2" @click="showDetail">{{ num }}<van-icon name="arrow" /></p>
      </div>
      <div class="box">
        <div class="content">
          <img class="banner" @click="goDeposit" :src="baseImage + 'e-banner.png?v=3'" alt="">

          <!-- <p class="b-title">双十一优惠券</p>
          <div class="list">
            <div v-for="i in double11Products" class="item">
              <img :src="i.imageUrl" alt="">
              <p class="p-n p11">{{ i.productName }}</p>
              <p class="p-num"><span>{{ i.value }}</span><span>运动币</span><span>/张</span></p>
              <p class="p-text">
                <template v-if="i.limitType == 0">
                  <span v-if="i.remainNum > 0">还可兑{{ i.remainNum }}张</span>
                  <span v-else-if="i.remainNum == 0">已达到兑换上限</span>
                </template>
                <template v-else-if='i.limitType == 1'>
                  <span v-if="i.remainNum > 0">数量有限</span>
                  <span v-else-if="i.remainNum == 0">库存不足</span>
                </template>
                <template v-else-if='i.limitType == 3'>
                  <span v-if="i.remainNum == 0">库存不足</span>
                  <template v-else>
                    <span v-if="i.redeemed">已达到兑换上限</span>
                    <span v-else>还可兑1张</span>
                  </template>
                </template>
                <span v-else></span>
                <span class="btn" @click="showExChangeFun(i)">{{ activityStatus == 0 ? '未开始' : (activityStatus == 1 ? '兑换'
                  :
                  '结束') }}</span>
              </p>
            </div>
          </div>
          <p class="b-title">日常优惠券</p>
          <div class="list">
            <div v-for="i in dailyProducts" class="item">
              <img :src="i.imageUrl" alt="">
              <p class="p-n">{{ i.productName }}</p>
              <p class="p-num"><span>{{ i.value }}</span><span>运动币</span><span>/张</span></p>
              <p class="p-text">
                <template v-if="i.limitType == 0">
                  <span v-if="i.remainNum > 0">还可兑{{ i.remainNum }}张</span>
                  <span v-else-if="i.remainNum == 0">已达到兑换上限</span>
                </template>
                <template v-else-if='i.limitType == 1'>
                  <span v-if="i.remainNum > 0">数量有限</span>
                  <span v-else-if="i.remainNum == 0">库存不足</span>
                </template>
                <template v-else-if='i.limitType == 3'>
                  <span v-if="i.remainNum == 0">库存不足</span>
                  <template v-else>
                    <span v-if="i.redeemed">已达到兑换上限</span>
                    <span v-else>还可兑1张</span>
                  </template>
                </template>
                <span v-else></span>
                <span class="btn" @click="showExChangeFun(i)">{{ activityStatus == 0 ? '未开始' : (activityStatus == 1 ? '兑换'
                  :
                  '结束') }}</span>
              </p>
            </div>
          </div> -->
          <div v-for="item in productList">
            <p class="b-title">{{item.title }}<span class="tips" v-if="item.tips">{{ item.tips }}</span></p>
            <div class="list">
              <div v-for="i in item.list" class="item" v-if="item.list" >
                <img :src="i.imageUrl" alt="">
                <p class="p-n">{{ i.productName }}</p>
                <p class="p-num"><span>{{ i.value }}</span><span>运动币</span><span>/张</span></p>
                <p class="p-text">
                  <template v-if="i.limitType == 0">
                    <span v-if="i.remainNum > 0">还可兑{{ i.remainNum }}张</span>
                    <span v-else-if="i.remainNum == 0">已达到兑换上限</span>
                  </template>
                  <template v-else-if='i.limitType == 1'>
                    <span v-if="i.remainNum > 0">数量有限</span>
                    <span v-else-if="i.remainNum == 0">库存不足</span>
                  </template>
                  <template v-else-if='i.limitType == 3'>
                    <span v-if="i.remainNum == 0">库存不足</span>
                    <template v-else>
                      <span v-if="i.redeemed">已达到兑换上限</span>
                      <span v-else>还可兑1张</span>
                    </template>
                  </template>
                  <span v-else></span>
                  <span class="btn" @click="showExChangeFun(i)">{{ activityStatus == 0 ? '未开始' : (activityStatus == 1 ?
                    '兑换'
                    :
                    '结束') }}</span>
                </p>
              </div>
            </div>
          </div>

        </div>
      </div>
    </div>
    <!-- 金币明细 -->
    <coinDetail type="mall" ref="detailRef" />
    <!-- 确认兑换 -->
    <van-popup v-model="changeShow" class="change-pop">
      <div class="ex-box">
        <div class="title">确认兑换</div>
        <p class="text">确认是否使用{{ changObj.value }}运动币兑换【{{ changObj.productName }}】吗?</p>
        <div class="btns">
          <span class="btn1" @click="changeShow = false">我再想想</span><span class="btn2" @click="goExChange">继续兑换</span>
        </div>
      </div>
    </van-popup>
  </div>
</template>
<script>
import { newAppBack, initBack, appOpenWeb } from '@/lib/appMethod'
import headBar from '@/components/app/headBar'
import userMixin from '@/mixin/userMixin'
import coinDetail from './components/coin-detail.vue'
import './components/index.less'

export default {
  components: {
    headBar,
    coinDetail,
  },
  mixins: [userMixin],
  data() {
    return {
      baseImage: 'https://img.chaolu.com.cn/ACT/double11-2024/',
      num: 0,
      activityStatus: '',
      dailyProducts: [],
      double11Products: [],
      changeShow: false,
      changObj: {},
      productList: []
    }
  },
  computed: {

  },
  async created() {
    initBack()
    await this.$getUserId()
    this.getNum()
    this.getProducts()
  },
  methods: {
    newAppBack,
    getActivity() {
      this.$axios.post(this.baseURLApp + '/punch/activity/midAutumnFestival', this.params).then((res) => {
        this.activityInfo = res.data
      })
    },
    getNum() {
      this.$axios.post(this.baseURLApp + '/double11/2024/sportsCoinMall/getCoinNum', { userId: this.userId }).then((res) => {
        this.num = res.data
      })
    },
    getProducts() {
      this.$axios.post(this.baseURLApp + '/double11/2024/sportsCoinMall/queryProducts', { userId: this.userId }).then((res) => {
        this.activityStatus = res.data.activityStatus
        // this.double11Products = res.data.double11Products
        // this.dailyProducts = res.data.dailyProducts
        this.productList = [{ title: '双十一优惠券', list: res.data.double11Products }, { title: '日常优惠券', list: res.data.dailyProducts }, { title: '福州马拉松名额', tips: '(仅为中签名额，报名费用需自行缴纳)', list: res.data.marathonProducts }]
      })
    },
    showExChangeFun(i) {
      if (!this.userId) {
        this.$store.commit('openLoginPopup', true)
        return
      }
      if (this.activityStatus == 0) {
        this.$toast('活动未开始');
      } else if (this.activityStatus == 2) {
        this.$toast('活动已结束');
      } else {
        this.changObj = i
        this.$axios.post(this.baseURLApp + '/double11/2024/sportsCoinMall/check', { userId: this.userId, token: this.token, productId: this.changObj.productId }).then((res) => {
          this.changeShow = true
        })
      }
    },
    goExChange() {
      this.$axios.post(this.baseURLApp + '/double11/2024/sportsCoinMall/redeem', { userId: this.userId, token: this.token, productId: this.changObj.productId }).then((res) => {
        this.changeShow = false
        this.$toast('兑换成功');
        this.getNum()
        this.getProducts()
      })
    },
    showDetail() {
      this.$refs.detailRef.showFun()
    },
    goDeposit() {
      appOpenWeb('', `${window.location.origin}/#/superdeer-activity/double11-2024/deposit`)
    }
  },
}
</script>
<style lang="less" scoped>
.bg {
  background: url(https://img.chaolu.com.cn/ACT/double11-2024/e-bg.png?v=2) no-repeat;
  background-size: 100%;
  height: 700px;


  .icon {
    padding-top: 93px;
    color: #F8CD66;
    line-height: 1;
    margin-left: 70px;


    .l1 {
      font-size: 26px;
    }

    .l2 {
      font-size: 66px;
      font-weight: 600;
      margin-top: 20px;
      display: flex;
      align-items: center;

      i {
        font-size: 26px;
        margin-left: 24px;
      }
    }
  }

  .box {
    margin-top: 66px;

    .content {
      min-height: 72vh;
      box-sizing: border-box;
      padding: 20px;

      .banner {
        width: 686px;
        height: 150px;
        margin: 0 auto;
      }

      .b-title {
        padding: 40px 0px 30px;
        font-size: 32px;
        color: #402828;
        font-weight: 600;

        .tips {
          color: #402828;
          font-size: 22px;
          font-weight: 400;
        }
      }

      .list {
        display: flex;
        justify-content: space-between;
        flex-wrap: wrap;

        .item {
          background-color: #fff;
          border-radius: 8px;
          padding: 24px;
          box-sizing: border-box;
          margin-bottom: 16px;
          width: 334px;
          flex-shrink: 0;
          line-height: 1;

          img {
            width: 286px;
            height: 172px;
          }

          .p-n {
            font-size: 26px;
            font-weight: 600;
            padding-top: 30px;
            line-height: 36px;
          }

          &:nth-child(1),
          &:nth-child(4) {
            .p-n.p11 {
              height: 72px;
            }
          }

          .p-num {
            color: #8A552A;
            margin-top: 25px;
            font-size: 20px;

            >span:first-child {
              font-size: 28px;
              margin-right: 3px;
            }

            >span:last-child {
              color: #6C727A;
            }
          }

          .p-text {
            display: flex;
            justify-content: space-between;
            font-size: 20px;
            display: flex;
            align-items: center;
            margin-top: 11px;

            .btn {
              font-size: 22px;
              font-weight: 600;
              color: #fff;
              padding: 9px 23px;
              background: linear-gradient(0deg, #F13F2A 0%, #FF7D43 100%);
              border-radius: 300px;

              &+.not {
                background-color: #6C727A;
              }
            }
          }
        }
      }
    }
  }
}

.change-pop {
  background-color: transparent;
  width: 600px;
  text-align: center;
  overflow: visible;

  .ex-box {
    background-image: url(https://img.chaolu.com.cn/ACT/double11-2024/c-bg.png);
    background-size: 100%;
    width: 620px;
    height: 473px;
    padding: 0px 80px;
    box-sizing: border-box;

    .title {
      // display: inline-block;
      box-sizing: border-box;
      margin: 0px auto;
      width: 343px;
      height: 100px;
      background-image: url(https://img.chaolu.com.cn/ACT/double11-2024/c-title.png);
      background-size: 100%;
      font-size: 52px;
      line-height: 1;
      font-family: PuHuiTi;
      color: #fff;
      padding-top: 20px;
      text-shadow: 2px 2px 2px #B84500;
      transform: translateY(-22px);

    }

    .text {
      padding-top: 40px;
      font-size: 26px;
      color: #8D3800;
    }

    .btns {
      margin-top: 49px;
      font-family: PuHuiTi;
      font-size: 32px;
      line-height: 1;
      color: #fff;
      text-shadow: 0px 0px 4px rgba(0, 0, 0, 0.3);
      display: flex;

      >span {
        display: inline-block;
        width: 220px;
        height: 93px;
        padding-top: 26px;
        box-sizing: border-box;
        background-image: url(https://img.chaolu.com.cn/ACT/double11-2024/btn1.png);
        background-size: 100%;
        background-repeat: no-repeat;

        &+span {
          margin-left: 18px;
          background-image: url(https://img.chaolu.com.cn/ACT/double11-2024/btn2.png);
        }
      }
    }
  }

}
</style>
