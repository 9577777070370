<template>
    <div class="periphery">
        <img src="https://img.chaolu.com.cn/ACT/double11-2024/main/rule.png" class="rule-logo" @click="$emit('rule')" />
        <div class="p-title">
            <img src="https://img.chaolu.com.cn/ACT/double11-2024/main/title.png" />
        </div>
        <div class="df">
            <div class="df-model" v-for="(item, index) in list" :key="item.id" v-if="index < 3">
                <template v-if="item.goodsName">
                    <div class="lmt"></div>
                    <div class="df-title puhui ellipsis">{{ item.goodsName }}</div>
                    <div class="df-add">
                        <img src="https://img.chaolu.com.cn/ACT/double11-2024/main/add.png" class="df-img" />
                        <div class="df-name">{{ item.goodsIntroduction }}</div>
                    </div>
                    <div class="df-pic">
                        <img :src="item.goodsImg" v-if="item.goodsImg" />
                    </div>
                    <div class="df-price BebasNeueBold">
                        <span class="dl">¥</span>
                        <span class="price">{{ item.salePrice }}</span>
                        <span class="qi puhui" v-if="item.showStart">起</span>
                        <span class="ori-price" v-if="item.originPrice">¥{{ item.originPrice }}</span>
                    </div>
                    <div class="df-sub" :class="{ dis: activityStatus != 'in' || goodsStatusMap[item.id] != 'CAN_BUY' }"
                        @click="buyCard(item)">{{ buttonText(item) }}</div>
                </template>
            </div>
        </div>
        <van-popup v-model="surePayShow" position="bottom" safe-area-inset-bottom class="pop-sure">
            <div class="buy-title f32 fw6">
                确认购买
                <van-icon name="cross" class="buy-close f36" @click="surePayShow = false" />
            </div>
            <div class="cbox" ref="refbox">
                <div class="f28 fw6">购买商品</div>
                <div class="t2">{{ goodsObj.tipMsg }}</div>
                <div class="goods-line">
                    <!-- <div class="pub-coner pc">线上商品</div> -->
                    <div class="jz puhui" v-if="goodsObj.goodsPrice">
                        <div class="tx1">价值</div>
                        <div class="jz-value"><span class="tx2">{{ goodsObj.goodsPrice }}</span>元</div>
                    </div>
                    <div class="goods-img">
                        <img :src="goodsObj.goodsImg" />
                    </div>
                    <div class="goods-info">
                        <div class="goods-name f34 fw6">{{ goodsObj.goodsName }}</div>
                        <div class="time f22" v-if="goodsObj.goodsText">{{ goodsObj.goodsText }}</div>
                        <div class="labels row-start-center">
                            <div class="label row-center-center f20 fw6" v-for="item, index in ['实体卡', '快递发货']"
                                :key="index">{{
                                    item }}</div>
                        </div>
                    </div>
                </div>
                <div class="goods-line">
                    <!-- <div class="pub-coner pc">实物商品</div> -->
                    <div class="jz puhui" v-if="goodsObj.sendPrice">
                        <div class="tx1">价值</div>
                        <div class="jz-value"><span class="tx2">{{ goodsObj.sendPrice }}</span>元</div>
                    </div>
                    <div class="goods-img">
                        <img :src="goodsObj.sendGoodsImg" />
                    </div>
                    <div class="goods-info">
                        <div class="goods-name f34 fw6">{{ goodsObj.sendGoodsName }}</div>
                        <div class="time f22">{{ goodsObj.sendGoodsText }}</div>
                        <div class="labels row-start-center">
                            <div class="label row-center-center f20 fw6">实物礼品</div>
                            <div class="label row-center-center f20 fw6">快递发货</div>
                        </div>
                    </div>
                </div>
                <div class="stock" v-if="goodsObj.stock!==undefined">
                    <template  v-if="goodsObj.stock>0">库存仅剩 <span class="stock-num">{{ goodsObj.stock }}</span> 份，先到先得！</template>
                    <template v-else>库存已售空，当前为预售商品</template>
                </div>
                <div class="f28 fw6 bmg">{{ goodsObj.tableText || '选择礼盒类型' }}</div>
                <div class="gift-type" v-if="peripheryObj && peripheryObj.length">
                    <div class="gift-type-model" :class="{ ac: acIndex == index }" v-for="item, index in peripheryObj"
                        @click="acIndex = index">
                        {{ item.tableName }}
                        <span v-if="item.priceText" class="diff">{{ item.priceText }}</span>
                    </div>
                </div>
                <div class="f28 fw6">收货地址</div>
                <div class="t2">请认真核对收货地址信息，下单成功后地址将不可修改。</div>
                <AddAddress @change="addressChange" color="#FFDE00" />
            </div>
            <div class="next fw6" @click="next">立即支付 <span>¥{{ goodsObj.itemInfo && goodsObj.itemInfo.salePrice
                    }}</span><del class="del" v-if="goodsObj.totalPrice">¥{{ goodsObj.totalPrice }}</del>
            </div>
        </van-popup>
    </div>
</template>
<script>
import baseStatus from '../config'
import AddAddress from './address'
const buyStatus = { ...baseStatus, 'NOT_STARTED': { name: '未开始', class: '' }, 'EXPIRED': { name: '已结束', class: '' }, }
export default {
    components: { AddAddress },
    data() {
        return {
            surePayShow: false,
            acIndex: 0
        }
    },
    props: {
        list: {
            type: Array
        },
        shopStyle: {
            value: {
                promoLabel: {},
                goodsLabel: {}
            },
            type: Object
        },
        activityStatus: {
            type: String
        },
        goodsStatusMap: {
            type: Object
        },
        peripheryObj: {
            type: Array
        }
    },
    computed: {
        goodsObj() {
            return this.peripheryObj && this.peripheryObj.length ? this.peripheryObj[this.acIndex] : {};
        }
    },
    created() {
        // this.getGoodStatus();
    },
    methods: {
        showFun() {
            this.acIndex = 0;
            this.surePayShow = true;
            this.$nextTick(() => {
                this.$refs.refbox.scrollTop = 0;
            })
        },
        buttonText(item) {
            if (buyStatus[this.goodsStatusMap[item.id]]) {
                let name = buyStatus[this.goodsStatusMap[item.id]].name;
                return name
            }
            return this.activityStatus === 'notin' ? '待开启' : this.activityStatus === 'end' ? '已结束' : '马上抢'
        },
        addressChange(obj) {
            this.address = obj;
            console.log(obj)
        },
        next() {
            // if (!this.goodsObj.sendGoodsName) {
            //     this.$toast('请选择类型');
            //     return;
            // }
            if (!this.address.userAddressId) {
                this.$toast('请选择地址')
                return;
            }
            this.surePayShow = false;
            this.$emit('pay', this.address, this.peripheryObj[this.acIndex].itemInfo)
        },
        buyCard(item) {
            this.$emit('buyCard', item)
        },
    }
};
</script>
<style lang="less">
.periphery {
    position: relative;
    padding-top: 22px;
    background: url(https://img.chaolu.com.cn/ACT/double11-2024/main/bg1.png);
    height: 686px;
    background-size: 100% 100%;
    margin: 31px 29px 0;

    div {
        box-sizing: border-box;
    }

    img {
        width: 100%;
        vertical-align: top;
    }

    .rule-logo {
        top: 40px;
        position: absolute;
        right: 4px;
        width: 36px;
        height: 62px;
    }

    .p-title {
        margin: 0 24px 24px;
        height: 151px;

        img {
            height: 100%;
        }
    }

    .df {
        width: 658px;
        height: 470px;
        margin: 0 auto;
        display: flex;
        justify-content: space-between;
        position: relative;

        .df-model {
            height: 230px;
            background: #FFFFFF;
            border-radius: 16px;
            position: absolute;
            width: 324px;
            padding: 20px;
            overflow: hidden;
            .lmt{
                width: 85px;
                height: 85px;
                position: absolute;
                background: url(https://img.chaolu.com.cn/ACT/double11-2024/main/lt.png);
                background-size: 100% 100%;
                right: 9px;
                top: 6px;
            }
            .df-title {
                font-size: 30px;
                color: #000000;
                line-height: 1;
            }

            .df-add {
                display: flex;
                align-items: center;
                margin-top: 10px;
                position: relative;
                z-index: 8;

                .df-img {
                    width: 32px;
                    height: 32px;
                    margin-right: 8px;
                }
            }

            .df-name {
                font-weight: 600;
                font-size: 28px;
                color: #000000;
                line-height: 1;
            }

            .df-price {
                margin: 18px 0 6px;
                position: relative;
                z-index: 10;
                color: #EE4832;

                .dl {

                    margin-right: 4px;
                    font-size: 26px;
                }

                .price {
                    position: relative;
                    top: 2px;
                    font-size: 44px;
                }

                .qi {
                    margin-left: 2px;
                    font-size: 22px;
                    font-weight: normal
                }

                .ori-price {
                    text-decoration: line-through;
                    color: #666;
                    font-size: 24px;
                    margin-left: 9px;
                }
            }

            .df-pic {
                width: 180px;
                height: 160px;
                position: absolute;
                right: 0;
                bottom: 0px;

                img {
                    height: 100%;
                    object-fit: cover;
                }
            }

            .df-sub {
                line-height: 44px;
                background: linear-gradient(0deg, #F35947 0%, #FBA27B 100%);
                border-radius: 22px;
                font-weight: 600;
                font-size: 24px;
                color: #FFFFFF;
                text-align: center;
                width: 110px;

                &.dis {
                    color: rgb(105, 108, 111);
                    background: linear-gradient(rgb(207, 208, 210) 0%, rgb(215, 215, 215) 100%);
                }
            }

            &:nth-child(1) {
                height: 470px;
                top: 0;
                left: 0;
                .lmt{
                    top: 10px;
                }
                .df-title {
                    font-size: 32px;
                }

                .df-pic {
                    width: 280px;
                    height: 250px;
                    position: initial;
                    margin-top: -24px;
                }

                .df-sub {
                    width: 100%;
                    line-height: 56px;
                    border-radius: 28px;
                }

                .df-price {
                    margin-bottom: 10px;
                    margin-top: 10px;
                    text-align: center;
                }

                .dl {
                    font-size: 28px;
                }

                .price {
                    font-size: 48px;
                }

                .ori-price {
                    font-size: 28px;
                    margin-left: 12px;
                }
            }

            &:nth-child(2) {
                right: 0;
                top: 0;
            }

            &:nth-child(3) {
                right: 0;
                bottom: 0;
            }
        }
    }

    .pop-sure {
        border-radius: 24px 24px 0 0;
        color: #242831;

        .t1 {
            display: none;
        }

        .buy-title {
            border-bottom: 1px solid #EEEEEE;
            height: 97px;
            line-height: 97px;
            padding: 0 32px;
            text-align: center;
            position: relative;
        }

        .buy-close {
            position: absolute;
            right: 32px;
            top: 50%;
            transform: translateY(-50%);

        }

        .cbox {
            padding: 37px 32px 32px;
            border-bottom: 1px solid #eee;
            height: 900px;
            overflow: auto;
        }

        .buy-tips {
            color: #9AA1A9;
            margin: 4px 0 22px;
        }

        .t2 {
            color: #666;
            font-size: 24px;
            margin: 7px 0 28px;
        }

        .goods-line {
            display: flex;
            align-items: center;
            margin: 22px 0 25px;
            height: 184px;
            background: #FFFAF7;
            border-radius: 16px;
            border: 3px solid #FFAC7F;
            padding: 0 20px;
            position: relative;

            .jz {
                width: 90px;
                height: 90px;
                background: url(https://img.chaolu.com.cn/ACT/double11-2024/main/pbg2.png);
                background-size: 100% 100%;
                color: #fff;
                position: absolute;
                right: 17px;
                top: 19px;
                text-align: center;

                .tx1 {
                    font-size: 16px;
                    margin: 18px 0 0
                }

                .jz-value {
                    font-size: 18px;
                    line-height: 1;
                }

                .tx2 {
                    font-size: 22px;
                    position: relative;
                    top: 2px;
                }
            }

            .pc {
                --left: 28px;
                z-index: 99;
                font-weight: bold;
                font-size: 24px;
                color: #FFFFFF;
                right: -2px;
                top: 0;
                margin-top: -23px;
                position: absolute;
                height: 40px;
                line-height: 40px;
                background: linear-gradient(0deg, #6F86D6 0%, #48C6EF 100%);
                border-radius: 10px;
                padding: 0 13px;
            }

            .goods-img {
                width: 144px;
                height: 144px;

                img {
                    width: 100%;
                    object-fit: contain;
                    height: 100%;
                    border-radius: 16px;
                    display: block;
                }
            }

            .goods-info {
                flex: 1;
                margin-left: 24px;

                .goods-name {
                    font-size: 30px;
                    color: #242831;
                    line-height: 1;
                }

                .time {
                    color: #242831;
                    margin: 12px 0 0;
                    line-height: 1;
                    font-size: 20px;
                }

                .labels {
                    margin: 12px 0 0;

                    .label {
                        height: 30px;
                        background: linear-gradient(90deg, #FFE8B5 0%, #FFD7C3 100%);
                        padding: 0 13px;
                        margin: 0 12px 0 0;
                        color: #B12E1D;
                        border-radius: 4px;
                    }
                }

                .price-line {
                    margin-top: 8px;
                    line-height: 1;

                    .dl {
                        color: #EE4832;
                        margin-right: 4px;
                    }

                    .price {
                        color: #EE4832;
                        margin-right: 9px;
                        position: relative;
                        top: 2px;
                    }

                    .ori-price {
                        text-decoration: line-through;
                        color: #6C727A;
                    }
                }
            }
        }

        .stock {
            height: 60px;
            background: #F3F8FF;
            border-radius: 8px;
            line-height: 60px;
            text-align: center;
            font-size: 24px;
            color: #000000;
            margin-top: 20px;
            .stock-num{
                color: #F51D00;
                font-weight: bold
            }
        }

        .bmg {
            margin-top: 35px;
        }

        .gift-type {
            display: flex;
            margin: 28px 0 48px;

            .gift-type-model {
                flex: 1;
                margin-right: 20px;
                height: 68px;
                line-height: 1;
                display: flex;
                align-items: center;
                justify-content: center;
                padding: 0 25px;
                background: #F5F5F5;
                border-radius: 8px;
                font-size: 26px;
                color: #000000;
                text-align: center;

                &:last-of-type {
                    margin-right: 0;
                }

                &.ac {
                    background: #FFDE00;
                    font-weight: bold;
                }

                .diff {
                    margin-left: auto;
                    color: #EE4832;
                }
            }
        }

        .city-list {
            padding: 0 0 32px;

            .city-model {
                margin-top: 24px;
                height: 128px;
                background: #F5F5F5;
                border-radius: 8px;
                padding: 0 32px;

                &.disabled {
                    color: #9AA1A9;
                }

                &.active {
                    border: 4px solid #242831;

                    .checkbox {
                        width: 48px;
                        height: 48px;
                        background: url(https://chaolucoachwebstatic.oss-cn-hangzhou.aliyuncs.com/static/img/spring2023/check.png);
                        background-size: 100% 100%;
                        border: none;
                        margin: 0;
                    }
                }

                .checkbox {
                    width: 32px;
                    height: 32px;
                    border: 1px solid #DDD;
                    border-radius: 50%;
                    margin-right: 8px;
                }

                .has-buy {
                    width: 102px;
                }
            }


        }

        .next {
            line-height: 96px;
            background: #FFDE00;
            border-radius: 8px;
            margin: 34px 32px 0;
            text-align: center;
            margin-bottom: 34px;
            color: #242831;

            span {
                color: #EE4832;
            }

            .del {
                color: #666666;
                font-size: 24px;
                margin-left: 17px;
            }
        }
    }
}
</style>