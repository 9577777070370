<template>
  <van-popup v-model="show" position="bottom" class="pop">
    <div class="title">
      <p class="text">活动规则</p>
      <img class="close" @click="show = false" :src="baseImage + 'close.png'" alt="">
    </div>
    <div class="box">
      <div class="content">
        <div class="rule f26">
          {{ rule }}
        </div>
      </div>
    </div>
  </van-popup>
</template>

<script>
import './index.less'

export default {
  components: {
  },
  data() {
    return {
      baseImage: 'https://img.chaolu.com.cn/ACT/double11-2024/',
      show: false,

      rule:`
一.活动时间:2024年10月10日10:00-11月15日
二.活动规则
1.完成指定任务即可获得相对应掷骰子次数，骰子机会在活动期间内有效，活动结束后自动失效，请在有效期内参与活动;
2.抽中的运动币请在“运动币商城”内查看，积累运动币可兑换的优惠券均有不同使用规则。其中所有的双11抵用券仅可用于11月11日10:00-11月15日活动指定产品，普惠品无法使用，不可与其他优惠券、课包券、礼品卡叠加使用;若您使用活动优惠券预约的团课申请退款，则优惠券不予退还，优惠券限用一次，不提现，不找零，不可转赠;
3.双十一优惠兑换券/48元团课券/299元私教券/福州马拉松名额【每个ID仅限兑换一次】，活动期间赠送的优惠券不可与其他优惠券、课包券、礼品卡看加使用:若您使用活动优惠券预约的团课申请退款，则优惠券不予退还;优惠券限用一次，不提现，不找零，不可转赠。
4.如果您对活动有疑问，请在超鹿APP上联系在线客服或者拨打400-700-5678进行反馈(在线时间:9:30-12:00/13:30-18:30)`
    }
  },
  computed: {

  },
  async created() {
  },
  methods: {
    showFun() {
      this.show = true
    }
  },
}
</script>

<style lang="less" scoped>
.rule {
  color: #402828;
  padding: 0 5px 30px;
  white-space: pre-wrap;
  p+p {
    margin-top: 30px;
  }
}
</style>
