<template>
  <van-popup v-model="show" position="bottom" class="pop">
    <div class="title">
      <p class="text">做任务·得机会</p>
      <img class="close" @click="show = false" :src="baseImage + 'close.png'" alt="">
    </div>
    <div class="box">
      <div class="content">
        <div class="task-item" v-for="(i, index) in list">
          <img class="icon" :src="baseImage + 'icon' + index + '.png'" alt="">
          <div class="info">
            <p>{{ textList[i.type] }} <span v-if="i.type != 4 && i.type != 2">（{{ i.completionNum }}/{{ i.totalNum || 1
            }}）</span>
            </p>
            <p>{{ tipsList[i.type] }}</p>
          </div>
          <div class="btn" :class="i.completionStatus == 1 ? 'not' : ''" @click="go(i)">{{
            i.completionStatus == 1 ? '已完成' : (i.type == 4 ? '去邀请' : '去完成') }}</div>
        </div>
      </div>
    </div>
  </van-popup>
</template>

<script>
import userMixin from '@/mixin/userMixin'
import { gotoAppPage, appOpenWeb } from '@/lib/appMethod'
import './index.less'

export default {
  components: {
  },
  mixins: [userMixin],
  data() {
    return {
      baseImage: 'https://img.chaolu.com.cn/ACT/double11-2024/',
      show: false,
      list: [],
      textList: ['', '', '打卡任意课程', '每日分享活动', '邀请有礼【新用户1人】', '每日登录活动页'],
      tipsList: ['', '', '结课骰子机会+2，上不封顶', '骰子次数+1，每日封顶5次', '骰子次数+3，上不封顶', '骰子次数+1，每日封顶2次',]
    }
  },
  methods: {
    showFun() {
      console.log('2121212121-------',this.userId)
      if (!this.userId) {
        this.$store.commit('openLoginPopup', true)
        return
      }
      this.list = []
      this.$axios.post(this.baseURLApp + '/double11/2024/diceTask/getDiceTaskMainPageInfo', { userId: this.userId, token: this.token }).then((res) => {
        this.show = true
        this.list = res.data.taskCompletionStatus
      })
    },
    go(i) {
      if (i.completionStatus == 1) {
        return
      }
      if (i.type == 2) { //打卡课程
        gotoAppPage(1)
      } else if (i.type == 3) {//分享
        this.show = false
        this.$emit('share');
      } else if (i.type == 4) {//邀新
        appOpenWeb('', `${window.location.origin}/#/invite-gift`)
      }
    }
  },
}
</script>

<style lang="less" scoped>
.task-item {
  background-color: #fff;
  padding: 28px;
  border-radius: 16px;
  display: flex;
  align-items: center;
  margin-bottom: 20px;

  .icon {
    width: 100px;
    height: 100px;
    margin-right: 23px;
  }

  .info {
    font-size: 22px;
    line-height: 1;
    flex: 1;
    padding-top: 10px;

    >p:first-child {
      font-size: 28px;
      line-height: 1;
      font-weight: 600;
      padding-bottom: 18px;
    }
  }

  .btn {
    background: linear-gradient(90deg, #FA597A 0%, #F83D6A 100%);
    font-size: 22px;
    line-height: 1;
    color: #fff;
    font-weight: 600;
    padding: 15px 27px;
    border-radius: 10px;

    &.not {
      opacity: 0.7;
    }
  }
}
</style>
