<template>
  <van-popup v-model="show" position="bottom" class="pop">
    <div class="title">
      <p class="text">{{ type == 'act' ? '骰子次数明细' : '运动币明细' }}</p>
      <img class="close" :src="baseImage + 'close.png'" alt="" @click="show = false">
    </div>
    <div class="box-bg">
      <div class="box">
        <div class="content">
          <p class="tips" v-if="type == 'act'">剩余掷骰次数：{{ total }}次</p>
          <div class="list" v-if="list.length > 0">
            <van-list v-model="loading" :finished="finished" finished-text="没有更多了" @load="getDetail"
              :immediate-check="false">
              <div class="item" v-for="i in list">
                <div>
                  <template v-if="type == 'act'">
                    <p class="fw6" v-if="i.diceUseType == 2">打卡课程「{{ i.courseTitle }}」</p>
                    <p class="fw6" v-else>{{ taskText[i.diceUseType] }}</p>
                  </template>
                  <p class="fw6" v-else>{{ i.title }}</p>
                  <p class="f22 mt20">{{ i.createTime || i.useDate }}</p>
                </div>
                <span class="fw6" :class="i.num < 0 ? 'red' : ''">{{ i.num < 0 ? '' : '+' }}{{ i.num }}</span>
              </div>
            </van-list>
          </div>
          <div class="empty" v-else>
            <img :src="baseImage + 'empty.png'" alt="">
            <p>暂无记录</p>
          </div>
        </div>
      </div>
    </div>
  </van-popup>
</template>

<script>
import { initBack } from '@/lib/appMethod'
import userMixin from '@/mixin/userMixin'
import './index.less'

export default {
  components: {
  },
  props: ['type'],
  mixins: [userMixin],
  data() {
    return {
      baseImage: 'https://img.chaolu.com.cn/ACT/double11-2024/',
      show: false,
      loading: false,
      finished: false,
      current: 1,
      list: [],
      total: 0,
      taskText: [
        '', '大富翁游戏', '每日打卡任意课程', '每日分享活动', '邀请有礼活动-邀请1人', '每日登录活动页',
      ]
    }
  },
  computed: {

  },
  created() {
    initBack()
  },
  methods: {
    showFun() {
      console.log('2121212121-------',this.userId)
      if (!this.userId) {
        this.$store.commit('openLoginPopup', true)
        return
      }
      this.list = []
      if (this.type == 'act') {
        this.getNum()
      }
      this.getDetail(1)
    },
    getDetail(num) {
      let url = this.type == 'act' ? '/double11/2024/diceTask/getUseDicePageList' : '/double11/2024/sportsCoinMall/pageSportsCoinDetail'
      this.$axios.post(this.baseURLApp + url, {
        userId: this.userId, current: num ?? this.current, size: 10
      }).then((res) => {
        this.show = true
        this.loading = false
        this.current++
        this.list = this.list.concat(res.data.records ?? res.data)
        let len = this.type == 'act' ? res.data.records?.length : res.data?.length
        if (len < 10) {
          this.finished = true
        }
      })
    },
    getNum() {
      this.$axios.post(this.baseURLApp + '/double11/2024/diceTask/getDiceNum', { userId: this.userId }).then((res) => {
        this.total = res.data
      })
    },
  },
}
</script>

<style lang="less" scoped>
.tips {
  font-size: 24px;
  color: #402828;
  text-align: center;
}

// .box-bg {
//   background-color: rgba(0, 0, 0, .5);
//   border-radius: 32px 32px 0px 0px;
//   padding-bottom: constant(safe-area-inset-bottom);
//   padding-bottom: env(safe-area-inset-bottom);
// }

.list {
  max-height: 1100px;
  overflow: scroll;

  .item {
    display: flex;
    border-bottom: 2px solid #EBD0A3;
    padding: 40px 0px;
    font-size: 28px;
    display: flex;

    >div:first-child {
      flex: 1;
      color: #402828;
    }

    .red {
      color: #F03C18;
    }
  }
}

.empty {
  text-align: center;
  font-size: 28px;
  color: #402828;
  font-weight: 400;
  padding-top: 215px;
  padding-bottom: 300px;

  img {
    width: 284px;
    margin-bottom: 34px;
  }
}
</style>