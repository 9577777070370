<template>
  <div class="marketing-template-main" :style="`background-color: ${AI.shopStyle.backgroundColor};`">
    <!-- 沉浸式头部 -->
    <nav-bar :header-title="AI.activityName" backColor="#fff" :show-back="true" :header-back="newAppBack"
      @setBarHeight="setBarHeight" />

    <div class="headbg">
      <!-- 主视觉 -->
      <img src="https://img.chaolu.com.cn/ACT/double11-2024/main/head.png" class="head-img" />
      <div class="top-button">
        <!-- 分享按钮 -->
        <template v-if="AI.shopStyle.shareStatus">
          <template v-if="AI.shopStyle.shareIconImg">
            <img class="icon" :src="AI.shopStyle.shareIconImg" @click="showShare" alt="" />
          </template>
          <template v-else>
            <div class="button f24" :style="`background: ${AI.shopStyle.shareIconColor}`" @click="showShare">分享</div>
          </template>
        </template>
        <!-- 规则按钮图标 -->
        <template v-if="AI.shopStyle.ruleStatus">
          <template v-if="AI.shopStyle.ruleIconImg">
            <img class="icon" :src="AI.shopStyle.ruleIconImg" @click="openRule(true)" alt="" />
          </template>
          <template v-else>
            <div class="button f24" :style="`background: ${AI.shopStyle.ruleIconColor}`" @click="openRule(true)">规则
            </div>
          </template>
        </template>
      </div>
      <img @click="showCouponFun" src="https://img.chaolu.com.cn/ACT/double11-2024/main/voucher.png" alt=""
        class="float-voucher" v-if="showVC" />
      <img @click="openNew" src="https://img.chaolu.com.cn/ACT/double11-2024/main/new.png" alt="" class="float-new"
        v-if="AI.showNewUserGoods" />
      <!-- <img v-if="showDJ" @click="openDJ" src="https://img.chaolu.com.cn/ACT/double11-2024/deposit/main.png" alt=""
        class="float-icon" /> -->
      <count-down v-if="AI.shopStyle.headerCountdown" :timeObj="AI" :status="activityStatus"
        @timeFinish="timeFinish"></count-down>
    </div>
    <div class="banner-dj" v-if="showDJ" @click="openDJ">
      <img src="https://img.chaolu.com.cn/ACT/double11-2024/main/wk.png" alt="" class="fh" />
    </div>
    <div class="banner-block" v-if="appTypeStr != 'mini'">
      <img src="https://img.chaolu.com.cn/ACT/double11-2024/main/banner1.png" alt="" class="fh" />
    </div>

    <!-- 带货人 信息 -->
    <div class="invite-block row-start-center f22"
      v-if="AI.shopStyle.inviterStatus && (inviteUserInfo.shareTeacherId || inviteUserInfo.shareUserId)">
      <div class="invite-head"><img :src="`${inviteUserInfo.shareUserHeadImg}`" alt="" /></div>
      <div class="invite-name ellipsis" :style="`color: ${AI.shopStyle.subfieldNameColor};`">
        <span>{{ inviteUserInfo.shareTitle }}</span> 邀请你参与{{ AI.activityName }}
      </div>
    </div>
    <Periphery :shopStyle="AI.shopStyle" :list="ZBlist" :activityStatus="activityStatus" :peripheryObj="peripheryObj"
      :goodsStatusMap="goodsStatusMap" @pay="peripheryPay" @buyCard="buyCard" @rule="showPeripheryPayRule"
      ref="refPeriphery" />
    <div class="fl-block" v-if="FLList.length">
      <img class='rule-btn' src="https://img.chaolu.com.cn/ACT/88buy-preheat-2024/making/rule-btn.png"
        @click="flRuleShowFun" alt="">
      <div class="fl-model" v-for="i in FLList">
        <div class="lb" v-if="goodsStatusMap[i.id] == 'HAVE_BUY'">
          <img src="https://img.chaolu.com.cn/ACT/88buy-preheat-2024/making/limit-buy.png" alt="">
        </div>
        <div class="fl-model-pic">
          <img :src="i.goodsImg" alt="">
        </div>
        <div class="fl-model-info ellipsis">
          <p class="fline1 ellipsis">{{ i.goodsName }}</p>
          <p class="f20 mt5">{{ i.hint }}</p>
          <p class="line2" v-if='i.productTags'><span v-for="tag in i.productTags.split(',')">{{ tag }}</span></p>
          <div class="fl-sub" :class="activityStatus == 'in' ? '' : (activityStatus == 'end' ? 'endbtn' : 'nobuy')"
            @click="goBuy(i)">
            <span class="th">特惠价</span>
            ￥<span class="num1">{{ i.salePrice }}</span><s class="num2">￥{{ i.originPrice }}</s>
          </div>
        </div>
      </div>
    </div>
    <div class="kill-block" v-if="killGoods.length">
      <img src="https://img.chaolu.com.cn/ACT/double11-2024/main/bg2_01.png" />
      <img class='rule-btn' src="https://img.chaolu.com.cn/ACT/88buy-preheat-2024/making/rule-btn.png"
        @click="killRuleShowFun" alt="">
      <div class="kill-item-box">
        <div class="kill-item" v-for="i in killGoods">
          <div class="limit-buy" v-if="goodsStatusMap[i.id] == 'HAVE_BUY'">
            <img src="https://img.chaolu.com.cn/ACT/88buy-preheat-2024/making/limit-buy.png" alt="">
          </div>
          <img :src="i.goodsImg" alt="">
          <p class="line1">{{ i.goodsName }}</p>
          <p class="f20 mt5">{{ i.hint }}</p>
          <p class="line2" v-if='i.productTags'><span v-for="tag in i.productTags.split(',')">{{ tag }}</span></p>
          <div class="btn" :class="activityStatus == 'in' ? '' : (activityStatus == 'end' ? 'endbtn' : 'nobuy')"
            @click="goBuy(i)">
            ￥<span class="num1">{{ i.salePrice }}</span><s>￥{{ i.originPrice }}</s>
          </div>
        </div>
      </div>
    </div>
    <img src="https://img.chaolu.com.cn/ACT/double11-2024/main/title2.png" alt="" class="nav-title" />
    <div class="template-content" ref="refContent">
      <van-sticky v-if="AI.partitions.subfieldsInfos && AI.partitions.subfieldsInfos.length > 1" style="width: 100%"
        :offset-top="barHeight" z-index="999" class="nav-sticky">
        <div class="nav" ref="nav">
          <!-- <div class="nav-top "> -->
          <div class="tm col-center-start" :class="{ active: tabIndex === index }"
            :style="`background: linear-gradient(180deg, ${tabIndex === index ? AI.shopStyle.clickSubfieldMenuStyle.bgTopColor : AI.shopStyle.defaultSubfieldMenuStyle.bgTopColor} 0%, ${tabIndex === index ? AI.shopStyle.clickSubfieldMenuStyle.bgBottomColor : AI.shopStyle.defaultSubfieldMenuStyle.bgBottomColor
              } 100%);
                  color: ${tabIndex === index ? AI.shopStyle.clickSubfieldMenuStyle.textColor : AI.shopStyle.defaultSubfieldMenuStyle.textColor}`"
            v-for="(item, index) in AI.partitions.subfieldsInfos" :key="index" @click="setTabIndex(index)">
            <div class="fw6 f28 ellipsis">{{ item.menuName }}</div>
            <div class="ii f24">{{ item.light }}</div>
          </div>
          <!-- </div> -->
        </div>
      </van-sticky>
      <div v-if="AI.partitions.subfieldsInfos && AI.partitions.subfieldsInfos.length > 1"
        class="title-line row-between-center f20" :style="`color: ${AI.shopStyle.subfieldNameColor};`">
        <p class="subfield-name puhui">{{ curGoods.subfieldName }}</p>
        <div class="row-start-center" @click="openRule(false)">
          <span class="ss">规则</span>
          <img class="triangle" src="https://img.chaolu.com.cn/ACT/anniversary-marketing-2024/triangle.png" alt="">
        </div>
      </div>

      <!-- 分栏 菜单  -->
      <van-sticky v-if="curGoods.showGroupMenu && curGoods.groupInfos.length > 1" :offset-top="barHeight + navHeight"
        z-index="999" class="tabar-sticky">
        <div class="group-bar row-start-center" ref="groupBox">
          <div class="group-bg" ref="groupBg">
            <template v-for="(item, index) in curGoods.groupInfos">
              <div v-if="!item.hidden"
                :class="['item', 'row-center-center', 'f28', 'fw6', curGoods.groupInfos.length > 5 ? 'flex-none' : 'flex-auto', groupIndex == index ? 'active' : '']"
                @click="toGroup(`group${item.id}`, index)" :ref="`item${item.id}`" :key="index">
                {{ item.groupMenuName }}
              </div>
            </template>
          </div>
        </div>
      </van-sticky>


      <!-- 分组上部广告位 -->
      <div class="ad-group col-start-center" v-if="curGoods.promotionalInfo.status && curGoods.promotionalInfo.topAds">
        <img :src="item.imgUrl" @click="toAppPath(item.url)" v-for="item in curGoods.promotionalInfo.topAds"
          :key="item.imgUrl" alt="" class="ad-item" />
      </div>
      <template v-for="group in curGoods.groupInfos">
        <div :key="group.id" :ref="`group${group.id}`">
          <!-- 分组的标题 -->
          <div v-if="curGoods.groupInfos.length > 1" class="group-name f30 fw6 row-start-center">
            {{ group.groupName }}
          </div>
          <div v-if="group.goodsInfos.length && !group.hidden"
            :class="['good-group', 'flex-wrap', group.onlyImgMode ? 'only-img row-start-start' : 'row-between-start']"
            :key="group.id">
            <style-one-card v-show="showWithCrow(good)" :cardObj="good" :shopStyle="AI.shopStyle"
              :status="goodsStatusMap[good.id]" :priceStatus="goodsPriceStatusMap[good.id]"
              :activityStatus="activityStatus" v-for="good in group.goodsInfos" :key="good.id"
              @buyCard="buyCard(good)"></style-one-card>
          </div>
        </div>
      </template>

      <!-- 分组下部广告位 -->
      <div class="ad-group col-start-center"
        v-if="curGoods.promotionalInfo.status && curGoods.promotionalInfo.bottomAds">
        <img :src="item.imgUrl" @click="toAppPath(item.url)" v-for="item in curGoods.promotionalInfo.bottomAds"
          :key="item.imgUrl" alt="" class="ad-item" />
      </div>

    </div>
    <div class="end f22">
      已经滑到底部啦
    </div>

    <MyDeposit ref="refMyDeposit" @pay="wkPay" />
    <van-popup v-model="cityConfirmShow" position="bottom" safe-area-inset-bottom class="pop-city-c" closeable>
      <img class="icon" src="https://img.chaolu.com.cn/ACT/88buy-preheat-2024/making/tips.png" alt="" />
      <p class="tips">
        您当前购买的产品，仅限<span v-show="cityName">{{ cityName }}</span>所有门店使用，请确认后再付款！
      </p>
      <div class="btn">
        <div @click="changeCityShowFun">切换城市</div>
        <div @click="citySure">确认</div>
      </div>
    </van-popup>
    <van-popup v-model="cityChangeShow" position="bottom" safe-area-inset-bottom class="pop-change" closeable
      @close="cityChangeClose">
      <p class="p-title">切换城市</p>
      <div class="city-list">
        <p v-for="(item, key) in cityJson" :class="key == unQueryCityId ? 'act' : ''" @click="unQueryCityId = key">{{
          item }}
        </p>
      </div>
      <div class="btn">
        <p>选择指定城市，卡仅限所选定的城市使用。</p>
        <p @click="comfrimcCityFun">确认</p>
      </div>
    </van-popup>

    <van-popup v-model="showUpgrade" position="center" class="pop-upgrade" v-if="upgradeInfo">
      <div class="utitle">
        <img src="https://img.chaolu.com.cn/ACT/double11-2024/main/utitle.png" alt="">
      </div>
      <div class="process">
        <div class="cfrom"><img :src="upgradeInfo.goodsInfo.goodsImg" /></div>
        <img src="https://img.chaolu.com.cn/ACT/double11-2024/main/to.png" class="cjt" />
        <div class="cto"><img :src="upgradeInfo.popGoodsInfo.goodsImg" /></div>
      </div>
      <div class="utext">再加 <span class="ap BebasNeueBold">{{ upgradeInfo.priceGap }}</span> 元即可获得{{
        upgradeInfo.popGoodsInfo.goodsName }}</div>
      <div class="uinfo">
        <div class="uinfo-1">
          <div class="l1">{{ upgradeInfo.popGoodsInfo.goodsName }}
            <div class="pub-coner uc" v-if="upgradeInfo.rebate">{{ upgradeInfo.rebate }}</div>
          </div>
          <div class="r1"><span v-if="upgradeInfo.newShowAfterVoucher">券后价</span> ¥{{ upgradeInfo.newPrice }}</div>
        </div>
        <div class="uinfo-2">
          <div class="l2">{{ upgradeInfo.popGoodsInfo.hint }}</div>
          <div class="r2">¥{{ upgradeInfo.popGoodsInfo.originPrice }}</div>
        </div>
      </div>
      <div class="u-subline">
        <div class="usub1 usm" @click="goPay">
          <div class="uc2">单节低至{{ upgradeInfo.oldSinglePrice }}元</div>
          <div class="usm-1">去支付 ¥{{ upgradeInfo.oldPrice }}</div>
          <div class="usm-2">{{ upgradeInfo.goodsInfo.goodsName }}</div>
        </div>
        <div class="usub2 usm" @click="upgradeGoods">
          <div class="uc2">单节低至{{ upgradeInfo.newSinglePrice }}元</div>
          <div class="usm-1">升级购 ¥{{ upgradeInfo.newPrice }}</div>
          <div class="usm-2">{{ upgradeInfo.popGoodsInfo.goodsName }}</div>
        </div>
      </div>
      <div class="pub-close" @click="showUpgrade = false">
        <img src="https://img.chaolu.com.cn/ACT/anniversary-2024/index/close-icon.png" />
      </div>
    </van-popup>

    <!-- 规则 弹窗 -->
    <van-popup v-model="isRuleShow" position="bottom" safe-area-inset-bottom class="pop-rule">
      <div class="pop-rule-title f32 fw6">
        活动规则
        <van-icon name="cross" class="pop-rule-close f28" color="#fff" @click="isRuleShow = false" />
      </div>
      <div class="pop-rule-content f26">
        {{ curRule }}
      </div>
    </van-popup>
    <van-popup v-model="isItemSelectShow" @click-overlay="close" position="bottom" safe-area-inset-bottom
      class="pop-item">
      <div class="buy-title f32 fw6">
        确认下单
        <van-icon name="cross" class="buy-close f36" @click="close" />
      </div>
      <div class="cbox" v-if="isItemSelectShow">
        <div class="f28 fw6">购买商品</div>
        <div class="goods-line">
          <div class="goods-img">
            <img :src="buyGoods.goodsImg" />
          </div>
          <div class="goods-info">
            <div class="goods-name f34 fw6">{{ buyGoods.goodsName }}</div>
            <div class="time f22" v-if="buyGoods.hint">{{ buyGoods.hint }}</div>
            <div class="labels row-start-center" v-if="buyGoods.productTags">
              <div class="label row-center-center f20 fw6" v-for="item, index in buyGoods.productTags" :key="index">{{
                item }}</div>
            </div>
            <div class="price-line row-start-end">
              <span class="dl f22 fw6">¥</span>
              <span class="price f32 fw6">{{ itemIndex === undefined ? buyGoods.salePrice :
                buyGoods.itemInfos[itemIndex].salePrice }}</span>
              <span class="ori-price f20">¥{{ itemIndex === undefined ? buyGoods.originPrice :
                buyGoods.itemInfos[itemIndex].originPrice }}</span>
            </div>
          </div>
        </div>
        <template v-if="itemList.length > 1">
          <div class="f28 fw6">{{ buyGoods.goodsItemExp }}</div>
          <div class="buy-tips f24">{{ buyGoods.notice }}</div>
          <div class="city-list">
            <div v-for="(item, index) in itemList" :key="index"
              :class="['city-model', 'row-between-center', 'fw6', itemIndex === index ? 'active' : '', itemStatusMap[item.id] === 'CAN_BUY' ? '' : 'disabled']"
              @click="chooseItem(index, itemStatusMap[item.id], item)">
              <p>{{ item.itemName }}&nbsp;&nbsp;
                <span v-if="itemPriceStatusMap[item.id] && itemPriceStatusMap[item.id].showAfterVoucher"
                  class="red f22 fw6">券后价</span>
                <span class="red f24 fw6">¥</span><span class="red">{{
                  itemPriceStatusMap[item.id] && itemPriceStatusMap[item.id].discountPrice || item.salePrice }}</span>
              </p>
              <div v-if="itemStatusMap[item.id] === 'CAN_BUY'" class="checkbox"></div>
              <img v-else src="https://img.chaolu.com.cn/ACT/marketing/soldout.png" alt="" class="has-buy">
            </div>
          </div>
        </template>
        <AddAddress v-if="buyGoods.goodsType === 22 && !buyGoods.showPeriphery" @change="addressChange"
          color="#FFDE00" />
      </div>
      <div
        :class="['next', 'fw6', (itemIndex !== undefined || (buyGoods.goodsType === 22 && buyGoods.itemInfos.length === 1)) ? '' : 'grey']"
        @click="next">立即购买
        {{ itemIndex === undefined ? '' :
          `￥${(itemPriceStatusMap[buyGoods.itemInfos[itemIndex].id] &&
            itemPriceStatusMap[buyGoods.itemInfos[itemIndex].id].discountPrice) ||
          buyGoods.itemInfos[itemIndex].salePrice}`
        }}
      </div>
    </van-popup>

    <!-- 海报 组件 -->
    <common-share :value="isShareShow" :shareParams="shareParams" @close="isShareShow = false"
      @share-success="isShareShow = false" :shareItem="['minifriend', 'postermessage', 'save']"
      :miniSharePoster="appTypeStr === 'mini'">
      <template slot="createDom">
        <div class="canvascss">
          <img src="https://img.chaolu.com.cn/ACT/double11-2024/main/sharebg.png" class="canvascss_bg" />
          <div class="canvascss_code">
            <img v-if="userDetail.qrCode" :src="`data:image/png;base64,${userDetail.qrCode}`" />
            <div class="canvascss_code_text">扫码领取福利</div>
          </div>
          <div class="canvascss_info">
            <img :src="userDetail.headImg" class="canvascss_head" />
            <div class="canvascss_full">
              <div class="canvascss_name ellipsis-2">
                {{ userDetail.nickName }}
                <!-- 啊实打实大师大萨达四大四大三 -->
              </div>
            </div>
          </div>

        </div>
      </template>
    </common-share>

    <!-- 升级提示 -->
    <van-popup v-model="isUpgradeShow" closeable :overlay="true" style="background-color: transparent; width: 84vw"
      closeable>
      <div class="error-box">
        <h4 class="f36 fw6">更新提示</h4>
        <p class="f28">发现新版本，新功能需要升级至最新版本。</p>
        <div @click="upgrade" class="btn f32 fw6 row-center-center">去升级</div>
      </div>
    </van-popup>
    <payPopupCard :showPayPopup.sync="showPayPopup" :pay-data="payData" :pay-url="payUrl"></payPopupCard>
    <CouponPopup ref="couponRef" @close="closeAnSetFun" />
    <EquityVenue :current-city-id="buyItem.extendInfo && buyItem.extendInfo.applyCityIds"
      :popShow.sync="venueSelectShow" :can-use-venue="buyItem.extendInfo && buyItem.extendInfo.applyVenueIds"
      @popfun="choseStorePop" @closefun="closeStorePop" :chose-item="{ areaId: '', venueId: [] }" />
    <van-popup v-model="isItemConfirmShow" position="center" class="pop-sure">
      <div class="sure-title f36 fw6">提示</div>
      <div class="sure-content f24">您当前购买的{{ curGoods.menuName }}为<span v-if="itemIndex != undefined">{{
        itemList[itemIndex].itemName }}</span>，请确认城市后再付款！</div>
      <div class="sure-sub f32 fw6" @click="nextConfirm">我知道了</div>
    </van-popup>
    <van-popup v-model="surpriseShow" position="center" class="pop-surprise" :close-on-click-overlay="false">
      <div class="s-box">
        <img class="close2" @click="surpriseShow = false"
          src="https://img.chaolu.com.cn/ACT/88buy-preheat-2024/making/close2.png" alt="">
        <div>
          <div class="s-item"
            :style="{ backgroundImage: 'url(https://img.chaolu.com.cn/ACT/double11-2024/main/s' + surpGood.id + '.png)' }">
            <div class="btn" @click="goSupBuy(surpGood)">去支付 ￥{{ surpGood.salePrice }}</div>
          </div>
        </div>
      </div>
    </van-popup>
    <Gift :list="ZBlist" :activityStatus="activityStatus" :goodsStatusMap="goodsStatusMap" @buyCard="buyCard"
      ref="refGift" />
    <template v-if="inviteUserInfo.shareTitle">
      <!-- 新人评价完推荐、签到推荐 的商品 -->
      <sign-in-recommended v-if="isSignInRecommended" :inviteUserInfo="inviteUserInfo" @buyCard="buyCard"
        @close="recommendedClose"></sign-in-recommended>
      <!-- 别人推荐的商品 -->
      <recommended-goods v-else :inviteUserInfo="inviteUserInfo" @buyCard="buyCard"
        @close="recommendedClose"></recommended-goods>
    </template>
  </div>
</template>
<script>
import { newAppBack, initBack, appPay, appPaySuccess, appOpenWeb, silentAppPay, upgrade, getAppVersion } from '@/lib/appMethod'
import MyDeposit from './components/myDeposit.vue'
import navBar from '@/components/nav-bar/nav-bar'
import commonShare from '@/components/commonShare/newShare'
import styleOneCard from './components/style-one-card'
import countDown from './components/count-down'
import userMixin from '@/mixin/userMixin'
import headerMixin from '@/mixin/headerMixin'
import jump from './jump'
import wx from 'weixin-js-sdk'
import payPopupCard from './components/pay-popup-card'
import CouponPopup from './components/coupon'
import AddAddress from './components/address'
import Periphery from './components/periphery.vue'
import EquityVenue from '@/components/cl-city-venue/equity-venue'
import Gift from './components/gift.vue';
import RecommendedGoods from './components/recommended-goods'
import SignInRecommended from './components/sign-in-recommended'

const webPath = `${window.location.origin}/#/superdeer-activity/double11-2024/main`
const miniPath = `/pages/webView/index?webUrl=${encodeURIComponent(webPath)}`
const activeId = 10120;
function isvisible(obj) {
  return obj.offsetWidth > 0 && obj.offsetHeight > 0;
}
export default {
  components: {
    navBar,
    commonShare,
    styleOneCard,
    countDown,
    MyDeposit,
    payPopupCard,
    CouponPopup,
    AddAddress,
    Periphery,
    EquityVenue,
    Gift,
    RecommendedGoods,
    SignInRecommended
  },
  mixins: [userMixin, headerMixin],
  data() {
    return {
      shopId: '', // 活动id
      AI: {
        // 活动总信息
        activityTrailer: {}, // 预告页
        partitions: {},
        shopStyle: {},
      },
      barHeight: 0,
      navHeight: 0,
      groupHeight: 0,
      tabIndex: 0, // 分栏的下标
      groupIndex: 0, // 分组的下标
      shareParams: {
        title: '双11主会场，超值优惠来袭 ',
        miniImage: 'https://img.chaolu.com.cn/ACT/double11-2024/main/mini.png',
        path: '',
        miniProgramType: window.location.origin.indexOf('web.chaolu.com') > -1 ? '0' : '2', // 正式0，开发1，体验2
        url: 'url',
        multiple: '0.95',
        userName: 'gh_7f5b57b6a76e',
      },
      userDetail: {}, //分享的用户信息
      inviteUserInfo: {}, // 邀请人信息
      isShareShow: false,
      isRuleShow: false,
      isItemSelectShow: false, // 规格选择弹窗
      address: {}, // 实体卡情况下 收货地址
      isItemConfirmShow: false, // 规格确认弹窗
      itemIndex: undefined, // 规格下标
      venueSelectShow: false, // 门店的弹窗
      isContinuousShow: false, // 连续包月弹窗
      isZhimaxianxiang: false, // 是否芝麻先享
      isRenewPayTypeShow: false, // 连续包月支付弹窗
      isCombinedBuyShow: false, // 组合购买提示弹窗
      renewPayType: 1,
      isUpgradeShow: false, // 升级提示
      buyGoods: {}, // 当前购买的商品 包含多个规格
      buyItem: {}, // 当前购买的规格
      showPayPopup: false, // 权益卡选择优惠券弹窗
      payData: {}, // 支付参数
      payUrl: '', // 支付回跳链接
      activityStatus: '', // 活动状态
      goodsStatusMap: {}, // 商品对应状态
      goodsPriceStatusMap: {}, // 商品对应的价格状态
      itemStatusMap: {}, // 当前商品下多规格状态
      itemPriceStatusMap: {}, // 当前商品下多规格的价格状态
      curRule: '', // 当前规则 可能是总规则 可能是菜单规则
      topArr: [], // 若存在分组 各个分组的offset集合
      isClickScroll: false,
      isHasActivedCardShow: false, // 已有生效中的卡
      hasActivedTip: '', //
      userExpansionSourceId: '',//获客来源--用拓crm
      cityChangeShow: false,
      sureItem: {},
      showDJ: false,
      showVC: false,
      showUpgrade: false,
      ZBlist: [{}, {}, {}],
      killGoods: [],
      FLList: [],
      cityConfirmShow: false,
      unQueryCityId: '',
      cityJson: {
        3: '福州市',
        '036652995ac5451fbb6791c3491e1c2d': '厦门市',
        '818447834079563776': '广州市'
      },
      surpriseShow: false,
      upgradeInfo: null,
      surpGood: {},//惊喜商品
      peripheryObj: [],
      popWindowSecond: 20,
      popWindowPrice: 1000
    }
  },
  computed: {
    // 当前菜单下的商品 tab
    curGoods() {
      let c = this.AI.partitions.subfieldsInfos ? this.AI.partitions.subfieldsInfos[this.tabIndex] : { groupInfos: [], promotionalInfo: {} }
      // 处理 卡片上 标签问题 逗号隔开的字符改为 数组
      c.groupInfos.map((group) => group.goodsInfos.map((good) => (good.productTags = Array.isArray(good.productTags) ? good.productTags : good.productTags ? good.productTags.split(',') : [])))
      return c
    },
    // 多规格情况下 规格的list
    itemList() {
      return this.buyGoods.itemInfos ? this.buyGoods.itemInfos : []
    },
    // 根据人群、所在城市 判断是否显示
    showWithCrow() {
      return (good) => {
        // 没有showWithCrow或者有的情况下状态可购买时显示
        return !good.showWithCrow || (good.showWithCrow && this.goodsStatusMap[good.id] === 'CAN_BUY')
      }
    },
    cityName() {
      return this.buyGoods && this.buyGoods.saleCity && this.buyGoods.saleCity.length ? this.buyGoods.saleCity.map(d => {
        return this.cityJson[d]
      }).join(',') : ''
    }
  },
  watch: {
    groupIndex(v) {
      const obj = this.curGoods.groupInfos[v];
      const pdom = this.$refs.groupBg;
      const dom = this.$refs['item' + obj.id][0];
      const add = dom.offsetWidth + dom.offsetLeft;
      if (dom.offsetLeft < pdom.scrollLeft || add > pdom.scrollLeft + pdom.offsetWidth) {
        pdom.scrollLeft = obj.groupbox_left; console.dir(obj.groupbox_left)
      }
    }
  },
  async created() {
    initBack()
    await this.$getAllInfo(['userId', 'cityId'])

    const q = this.$route.query

    if (this.appTypeStr === "mini") {
      this.countPoint('410', '410', '40059')
    } else {
      this.countPoint('410', '410', '40060')
    }
    // 存在推荐商品 需要强制登录
    if (q.goodsId && !this.userId) {
      this.$store.commit('openLoginPopup', true)
      return
    }

    // 若是教练邀请 获取教练id
    this.inviteTeacherId = q.teacherId || q.inviteTeacherId
    this.inviteUserId = q.inviteUserId
    this.userExpansionSourceId = q.userExpansionSourceId

    // 来源
    this.from = q.from
    this.queryCityId = q.shopCityId || this.cityId // 区别于mixin里的cityId
    console.log('cid' + this.queryCityId)
    this.unQueryCityId = this.queryCityId
    // 本次商城的id
    this.shopId = activeId

    // 分享的链接
    this.shareParams.path = `${miniPath}&id=${this.shopId}`

    // 推荐弹窗  签到或评价会弹的
    this.isSignInRecommended = q.reason ? true : false

    if (this.inviteTeacherId) {
      this.countPoint('410', '410', '40058')
    }
    // 获取活动总信息
    const r = await this.getActivity()
    if (r === '0') return
    this.getVoucher();
    this.getBuy();
    // 海报的二维码
    if (this.userId) this.initQr()

    // 获取绑定关系
    this.getBindInfo()

    // 小程序卡片分享
    if (this.AI.shopStyle.shareStatus) {
      this.miniShare()
    }
    if (this.activityStatus == 'in' && !q.goodsId && this.killGoods.length) {
      this.timerGoods();
    }
    // 支付成功回调 小程序购买成功提示
    appPaySuccess().then(() => {
      this.paySuccessCallback()
    })
  },
  mounted() {
    document.addEventListener('visibilitychange', this.vc)
  },
  beforeDestroy() {
    clearTimeout(this.gtimer);
    document.removeEventListener('visibilitychange', this.vc)
  },
  methods: {
    vc() {
      if (document.visibilityState === 'visible' && !this.AI.activityTrailer) {
        setTimeout(() => {
          this.getGoodStatus()
          this.getBuy()
          this.getVoucher();
        }, 500)
      }
    },
    newAppBack,
    upgrade,
    getActivity() {
      return this.$axios
        .post(`${this.baseURLApp}/activityShopWeb/getShop202411`, {
          cityId: this.queryCityId,
          shopId: this.shopId,
          userId: this.userId,
        })
        .then((res) => {
          const d = res.data.partitions.subfieldsInfos;
          if (d.length > 2) {
            res.data.partitions.subfieldsInfos = d.slice(0, 6); console.log(res.data.partitions.subfieldsInfos)
            if (d.length > 7) {
              this.ZBlist = d[6].groupInfos[0].goodsInfos.map(d => {
                return { ...d, showPeriphery: true }
              });
              this.ZBrule = d[6].saleRule
              this.killGoods = d[7].groupInfos[0].goodsInfos;
              this.killRule = d[7].saleRule;
              if (d.length > 8) {
                this.FLList = d[8].groupInfos[0].goodsInfos;
                this.flRule = d[8].saleRule;
              }
            }

          }

          // 活动总信息
          this.AI = res.data;
          if (!this.AI.activityTrailer) {
            if (this.AI.popWindowSecond) this.popWindowSecond = this.AI.popWindowSecond;
            if (this.AI.popWindowPrice) this.popWindowPrice = this.AI.popWindowPrice;
            // 设置活动状态
            this.activityStatus = this.AI.diffStartTime > 0 ? 'notin' : this.AI.diffEndTime > 0 ? 'in' : 'end'
            // 设置页面标题
            document.title = this.AI.activityName
            // 设置小程序卡片分享内容
            // this.shareParams.title = this.AI.wxShareContent
            // this.shareParams.miniImage = this.AI.wxShareImg || ''
            // 兜底
            // if (!this.AI.shopStyle.groupTitleImg) this.AI.shopStyle.groupTitleImg = 'https://img.chaolu.com.cn/ACT/anniversary-2023/coverbg.png'
            // // 预加载海报图
            // if (this.AI.shareImg) {
            //   let img = new Image()
            //   img.src = this.AI.shareImg
            //   img.onload = () => { }
            // }

            this.bindScroll();
          }
        })
        .catch((res) => {
          console.log(res);
          this.$toast(res.msg);
          setTimeout(() => {
            this.newAppBack()
          }, 2000)
          return res.code
        })
    },
    timerGoods() {
      clearTimeout(this.gtimer);
      this.$addTime = 0;
      this.doTime();
    },
    doTime() {
      const dom1 = document.querySelectorAll('.van-overlay');
      const dom2 = document.querySelectorAll('.van-popup');
      const all = [...dom1, ...dom2];
      let openPop = false;
      for (let i = 0; i < all.length; i++) {
        if (isvisible(all[i])) {
          openPop = true;
          break;
        }
      }

      if (openPop) {
        this.gtimer = setTimeout(this.doTime, 1000);
        return;
      }
      this.$addTime++;
      if (this.$addTime > this.popWindowSecond) {
        clearTimeout(this.gtimer);
        this.getSurpriseList();
      } else {
        this.gtimer = setTimeout(this.doTime, 1000)
      }
    },
    upgradeGoods() {
      this.showUpgrade = false;
      this.buyGoods = this.upgradeInfo.popGoodsInfo
      this.buyItem = this.buyGoods.itemInfos[0]
      this.goPay();
    },
    citySure() {
      this.cityConfirmShow = false;
      this.gogoBuy();
    },
    nextConfirm() {
      if (this.itemIndex === undefined) return

      this.isItemConfirmShow = false
      this.isItemSelectShow = false

      this.buyCard(this.buyGoods)
    },
    closeStorePop() {
      this.itemIndex = this.itemIndex === undefined ? undefined : this.itemIndex
    },
    comfrimcCityFun() {
      if (!this.unQueryCityId) {
        this.$toast('请选择');
        return;
      }
      this.queryCityId = this.unQueryCityId
      this.hasStickyTag = false
      this.cityChangeShow = false
      this.getActivity()
    },
    cityChangeClose() {
      this.cityChangeShow = false
      this.unQueryCityId = this.queryCityId
    },
    changeCityShowFun() {
      this.cityChangeShow = true
      this.cityConfirmShow = false
    },
    closeAnSetFun() { },
    showCouponFun() {
      this.countPoint('410', '410', '40061')
      let d = { cityId: this.queryCityId, shopId: this.shopId, token: this.token, userId: this.userId }
      this.$refs.couponRef.showFun(d)
    },
    goBuy(item) {
      this.buyCard(item)
      // let point = { 2683: '40035', 2684: '40036', 2685: '40037', 2687: '40038', 2688: '40038', 2689: '40038' }
      // this.countPoint('404', '404', point[item.id])
    },
    killRuleShowFun() {
      this.curRule = this.killRule;
      this.isRuleShow = true
    },
    flRuleShowFun() {
      this.curRule = this.flRule;
      this.isRuleShow = true
    },
    showPeripheryPayRule() {
      this.curRule = this.ZBrule;
      this.isRuleShow = true
    },
    openNew() {
      this.$router.push('/superdeer-activity/double11-2024/new-people?shopCityId=' + this.queryCityId);
    },
    openDJ() {
      // this.countPoint('408', activeId, '40050', this.userId)
      this.$refs.refMyDeposit.show();
    },
    previewFinish() {
      this.getActivity()
    },
    setBarHeight(h) {
      this.barHeight = h || 0
    },
    toPath(path) {
      const p = path.split('?')[0]
      let p1 = path.split('?')[1] ? `&${path.split('?')[1]}` : ''

      if (this.inviteTeacherId) {
        p1 = `${p1}&inviteTeacherId=${this.inviteTeacherId}`
        path = `${path}&inviteTeacherId=${this.inviteTeacherId}`
      }
      if (this.inviteUserId) {
        p1 = `${p1}&inviteUserId=${this.inviteUserId}`
        path = `${path}&inviteUserId=${this.inviteUserId}`
      }

      const hasDomain = p.indexOf('http') > -1
      if (this.appTypeStr === 'mini') {
        wx.miniProgram.navigateTo({
          url: `/pages/webView/index?webUrl=${hasDomain ? `${p}${p1}` : window.location.origin + '/#' + `${p}${p1}`}`,
        })
      }
      if (this.appTypeStr === 'and') {
        App.intentToActivity(
          JSON.stringify({
            className: '.ui.AppWebViewLaunchStandardActivity',
            data: [
              {
                key: 'url',
                value: path,
              },
              {
                key: 'removeTitle',
                value: '1',
              },
            ],
          })
        )
      }
      if (this.appTypeStr === 'ios') {
        hasDomain ? appOpenWeb('', path) : this.$router.push(p)
      }
    },
    toAppPath(obj) {
      if (!obj) return
      if (obj.type === '21') {
        if (this.inviteTeacherId) obj.extendJson.url = `${obj.extendJson.url}&inviteTeacherId=${this.inviteTeacherId}`
        if (this.inviteUserId) obj.extendJson.url = `${obj.extendJson.url}&inviteUserId=${this.inviteUserId}`
      }
      jump(obj)
    },
    async openItemSelect() {
      await this.getItemStatus()

      this.itemIndex = this.buyGoods.itemInfos.length > 1 ? undefined : 0
      this.isItemSelectShow = true
    },
    next() {
      // 当前规格下是否包含 指定门店的情况
      const ext = this.buyItem.extendInfo
      if (ext && ext.perOnlyVenue) {
        this.venueSelectShow = true
        return
      }
      // 只要是多规格 就判断是否弹规格确认弹窗
      if (this.buyGoods.itemInfos.length > 1) {
        this.isItemConfirmShow = this.itemIndex != undefined ? true : false
      } else {
        // 实体卡的情况下单独判断
        if (this.buyGoods.goodsType === 22) {
          if (this.address.userAddressId) {
            this.itemIndex = 0
            this.buyCard(this.buyGoods)
          } else {
            this.$toast('请填写邮寄地址')
          }
        }
      }

    },
    chooseItem(i, status, item) {
      if (status !== 'CAN_BUY') return
      this.itemIndex = i
      this.buyItem = item
    },

    checkCanBuy() {
      this.$toast.loading({
        duration: 0,
        forbidClick: true,
        loadingType: 'spinner',
      })
      return this.$axios
        .post(this.baseURLApp + '/activityShopOrder/checkBuy', {
          goodsId: this.buyGoods.id,
          goodsItemId: this.buyItem.id,
          shopId: this.shopId,
          userId: this.userId,
        })
        .then((r) => {
          this.$toast.clear()
          return r.data
        })
        .catch(() => {
          this.$toast.clear()
          return 0
        })
    },
    peripheryPay(address, selectItem) {
      console.log(address)
      this.address = address
      this.buyItem = selectItem;
      this.goPay();
    },
    async buyCard(item, nowFrom) {
      this.countPoint('410', item.id, '40064')
      clearTimeout(this.gtimer);
      // 未登录
      if (!this.userId) {
        this.$store.commit('openLoginPopup', true)
        return
      }
      if (this.activityStatus == 'end') {
        this.$toast('活动已结束')
        return
      } else if (this.activityStatus != 'in') {
        this.$toast('活动还没开始哦')
        return
      }
      // item可能来自推荐弹窗组件item.isRecommended:true
      // 推荐的商品可能不是当前分组下的商品 所以goodsStatusMap无法使用
      this.buyGoods = item
      if (item.showPeriphery) {//周边
        if (this.goodsStatusMap[item.id] !== 'CAN_BUY') {
          this.$toast('不符合购买条件')
          return
        }
        if (item.goodsType == 24 || item.goodsType == 25 || item.itemInfos[0].extendInfo.checkCity) {
          this.cityConfirmShow = true;
          return;
        }
      } else {
        // 如果由推荐商品的弹窗触发的 必然是能购买的
        if (!item.isRecommended && this.goodsStatusMap[item.id] !== 'CAN_BUY') {
          this.$toast('不符合购买条件')
          return
        }
        this.buyItem = this.itemIndex === undefined ? item.itemInfos[0] : item.itemInfos[this.itemIndex]
        // 规格数量大于1或者为实体卡时 打开规格选项弹窗
        if (((item.itemInfos.length > 1 || [22].includes(item.goodsType)) && this.itemIndex === undefined)) {
          this.openItemSelect();
          return
        }

        // 当前规格下是否包含 指定门店的情况
        const ext = this.buyItem.extendInfo
        if (ext && ext.perOnlyVenue && this.itemIndex === undefined) {
          this.itemIndex = 0
          this.venueSelectShow = true
          return
        }

        this.itemIndex = undefined
        this.nowFrom = nowFrom
        // 城市筛选
        if (item.goodsType == 24 || item.goodsType == 25) {
          this.cityConfirmShow = true;
          return;
        }
      }
      this.gogoBuy()
    },
    getPPObj() {
      this.$toast.loading({
        duration: 0,
        forbidClick: true,
        loadingType: 'spinner',
      })
      return this.$axios
        .post(`${this.baseURLApp}/activityShopWeb/getGoodPop`, {
          goodsId: this.buyGoods.id,
          goodsItemId: this.buyItem.id,
          shopId: this.shopId,
          userId: this.userId,
        })
        .then((res) => {
          this.$toast.clear();
          return res.data
        }).catch(() => {
          this.$toast.clear();
        })
    },
    async gogoBuy() {
      if (this.buyGoods.showPeriphery) {
        const data = await this.getPPObj();
        if (data && data.length) {
          this.peripheryObj = data;
        } else {
          this.$toast('数据异常');
        }
        this.$refs.refPeriphery.showFun();
        return;
      }
      if (this.buyGoods.goodsType == 24) {
        const data = await this.checkUpgrade(this.buyGoods.id)
        if (data && data.popGoodsInfo) {
          this.upgradeInfo = data;
          this.showUpgrade = true;
          return;
        }
      }
      this.goPay();
    },
    async checkUpgrade(goodsId) {
      return this.$axios
        .post(`${this.baseURLApp}/activityShopWeb/getSurpriseGoods`, {
          goodsId,
          shopId: this.shopId,
          userId: this.userId,
        })
        .then((res) => {
          return res.data
        })
    },
    wkPay(item) {//尾款
      this.buyGoods = item
      this.buyItem = item.itemInfos[0];
      this.goPay();
    },
    async goPay() {
      const res = await this.checkCanBuy(this.buyGoods)
      if (!res) {
        this.$toast('无法购买')
        return
      }
      const obj = JSON.parse(JSON.stringify(this.buyGoods))
      this.payAction(obj)
    },
    async payAction() {
      sessionStorage.setItem("goodsPrice", this.buyItem.salePrice);
      const payData = this.getPayParams(); console.log(payData);
      // 如果是教练分享单个商品的情况 支付完回到页面还是只能看到单商品的弹窗 因此回调链接加上goodsId
      const goodsId = this.buyGoods.isRecommended ? this.buyGoods.id : ''
      const url = `${this.shareParams.path}&userId=1&goodsId=${goodsId}&shopCityId=${this.queryCityId}`

      // 判断是否芝麻先享 或者 周期付
      // switch (this.buyItem.extendInfo.payType) {
      //   case 'ALI_PRE_ENJOY': // 芝麻先享
      //     payData.tradeType = 'SUBSCRIPTION' // 按照app的方式
      //     if (this.appTypeStr === 'mini') { // 小程序环境 跳转待支付页 复制芝麻口令
      //       const goodsId = this.buyGoods.isRecommended ? this.buyGoods.id : ''
      //       const url = `${this.shareParams.path}&userId=1&goodsId=${goodsId}&shopCityId=${this.queryCityId}`
      //       const redirectUri = encodeURIComponent(url)

      //       wx.miniProgram.navigateTo({
      //         url: `/pages/webViewPay/index?payData=${JSON.stringify(payData)}&redirect_uri=${redirectUri}`,
      //       })
      //     } else {
      //       silentAppPay(2, payData, this.shareParams.path)
      //     }
      //     return
      //   case 'AUTO_RENEW': // app周期付
      //     payData.tradeType = this.appTypeStr === 'mini' ? 'APPLET_WITH_CYCLE' : 'APP_WITH_CYCLE'
      //     silentAppPay(this.renewPayType, payData, this.shareParams.path)
      //     this.close()
      //     return
      // }

      if (this.appTypeStr === 'mini') {
        payData.wxPayType = 'applet'
      } else {
        appPaySuccess().then(() => {
          this.paySuccessCallback()
        })
      }

      // 弹个选择优惠券的窗
      // if (this.buyGoods.canUseVoucher) {
      this.showPayPopup = true
      this.payData = payData
      this.payUrl = url
      // } else {
      //   appPay(payData, url)
      // }

      this.close()
    },
    getPayParams() {
      return {
        subject: this.buyItem.productName,
        extendsJson: {
          appVersion: '2.1.11',
          shopId: this.shopId,
          activityShopGoodsId: this.buyGoods.id + '',
          activityShopGoodsItemId: this.buyItem.id,
          from: this.nowFrom || this.from,
          userAddressId: this.address.userAddressId,
          perUseVenueId: this.perUseVenueId,
          prePayOrderNo: this.buyGoods.prePayOrderNo
        },
        totalAmount: this.buyItem.salePrice + '',
        venueId: '31',
        cityId: this.queryCityId,
        goodsNum: [12, 22].includes(this.buyGoods.goodsType) ? '1' : undefined,
        isApp: '1',
        type: this.buyGoods.goodsType, // 礼品卡12  课包10 实体卡22
        userId: this.userId,
        token: this.token,
        extendsType: '4',
        mouldId: this.buyItem.productId,
        whetherPrivacy: 0,
        originalPriceStr: '',
        title: this.appTypeStr === 'mini' ? '商品名称' : this.buyItem.productName,
      }
    },

    async timeFinish() {
      if (this.activityStatus == 'in') {
        this.activityStatus = 'end'
        this.getGoodStatus()
      } else {
        await this.getActivity()
      }
    },
    getBindInfo() {
      this.$axios
        .post(this.baseURLApp + '/userDistribute/bindUser', {
          hasWx: true,
          activityNo: this.shopId, // shopId就是老接口里的activityNo
          userId: this.userId,
          saleTeacherId: this.inviteTeacherId,
          saleUserId: this.inviteUserId,
        })
        .then((res) => {
          const d = res.data
          this.inviteUserInfo = d || {}
          this.inviteTeacherId = d && d.shareTeacherId

          // 2024.04.08 用户拓展的需求 荣仁要求改的 🔽
          this.$axios
            .post(this.baseURLApp + '/userExpansionBing/scanCode', {
              activityId: this.shopId,
              saleTeacherId: this.inviteTeacherId,
              saleUserId: this.inviteUserId,
              userId: this.userId,
              userExpansionSourceId: this.userExpansionSourceId
            })
            .then((res) => {
              if (res.data.code === '999') {
                this.AI = {
                  // 活动总信息
                  activityTrailer: {}, // 预告页
                  partitions: {},
                  shopStyle: {},
                }
                this.$toast('不符合绑定人群，无法访问~')
                setTimeout(() => {
                  this.newAppBack()
                }, 2000)
              }
            })
          // 2024.04.08 用户拓展的需求 荣仁要求改的 🔼
        })
    },

    // 获取当前菜单tab下商品的id集合 单独获取商品的购买状态 结果用id匹配 {34: 'CAN_BUY'}
    getGoodStatus() {
      const idArr = []
      this.curGoods.groupInfos.map((group) => {
        group.goodsInfos.map((goods) => {
          idArr.push(goods.id)
        })
      })
      this.ZBlist.map((goods) => {
        idArr.push(goods.id)
      })
      if (this.killGoods.length) {
        this.killGoods.map((goods) => {
          idArr.push(goods.id)
        })
      }
      if (this.FLList.length) {
        this.FLList.map((goods) => {
          idArr.push(goods.id)
        })
      }
      const params = {
        goodsId: idArr,
        shopId: this.shopId,
        userId: this.userId,
      }
      // 获取按钮状态
      this.$axios.post(`${this.baseURLApp}/activityShopWeb/getGoodsStatus`, params).then((res) => {
        this.goodsStatusMap = res.data.goodsStatusMap;
        this.$toast.clear()

        // 判断 分栏底下的商品 是否因为人群关系 全部隐藏 此时 需要隐藏整个分栏
        let change = false;
        this.curGoods.groupInfos.map((group) => {
          let hidden = false
          group.goodsInfos.map((goods) => {
            if (!goods.showWithCrow || (goods.showWithCrow && this.goodsStatusMap[goods.id] === 'CAN_BUY')) {
              hidden = false;
            } else {
              hidden = true;
              change = true;
            }
          })
          this.$set(group, 'show', !hidden)
        })
        if (change) {
          console.log('change')
          setTimeout(() => {
            this.setScroll();
          })
        }
      })

      // 获取价格的状态
      this.$axios.post(`${this.baseURLApp}/activityShopWeb/getGoodMaxDiscountV2`, params).then((res) => {
        this.goodsPriceStatusMap = res.data.goodsDiscountMap;
      })
    },

    // 获取当前购买商品的规格id集合 结果用id匹配 {34: 'CAN_BUY'}
    getItemStatus() {
      this.$toast.loading({
        duration: 0,
        forbidClick: true,
        loadingType: 'spinner',
      })
      const idArr = this.buyGoods.itemInfos.map((item) => item.id)

      const params = {
        goodItems: idArr,
        goodsId: this.buyGoods.id,
        shopId: this.shopId,
        userId: this.userId,
      }
      // 规格价格
      this.$axios.post(`${this.baseURLApp}/activityShopWeb/getGoodItemMaxDiscountV2`, params).then((res) => {
        this.itemPriceStatusMap = res.data.goodsDiscountMap
      })
      // 规格按钮状态
      return this.$axios.post(`${this.baseURLApp}/activityShopWeb/getGoodsItemStatus`, params).then((res) => {
        this.itemStatusMap = res.data.goodsStatusMap
        this.$toast.clear()
      })
    },
    getBuy() {
      this.$axios.post(this.baseURLApp + "/perUseGoods/getPreUseGoodsRecord", {
        hasWx: true,
        userId: this.userId,
        page: 1,
        size: 1
      }).then(res => {
        if (res.data.records && res.data.records.length) {
          this.showDJ = true;
        }
      })
    },
    getVoucher() {
      let params = { cityId: this.queryCityId, shopId: this.shopId, token: this.token, userId: this.userId, page: 1, rows: 10 }
      this.$axios.post(`${this.baseURLApp}/user_voucher/queryUserAllVoucherInShop`, params).then(res => {
        this.showVC = res.data && res.data.length > 0;
      })
    },
    paySuccessCallback() {
      // this.$toast('支付成功')
      setTimeout(() => {
        this.showPayPopup = false;
        this.perUseVenueId = ''
        this.perVenueName = ''
        this.getGoodStatus()
        const price = sessionStorage.getItem('goodsPrice')
        if (price && parseFloat(price) >= parseFloat(this.popWindowPrice)) {
          sessionStorage.removeItem("goodsPrice");
          setTimeout(() => {
            this.$refs.refGift.showFun();
          }, 1000)
        }
      }, 500)
    },
    showShare() {
      if (!this.userId) {
        this.$store.commit('openLoginPopup', true)
        return
      }
      this.countPoint('410', '410', '40063')
      this.isShareShow = true
    },

    initQr() {
      this.$axios
        .post(`${this.baseURLApp}/qrCode/createOrdinaryCode`, {
          token: this.token,
          userId: this.userId,
          width: 240,
          height: 240,
          webUrl: 'https://web.chaolu.com.cn/#/ordinary_004001',
          scene: JSON.stringify({
            source: `activity_${this.shopId}`,
            id: this.shopId,
            webUrl: webPath,
          }),
        })
        .then((res) => {
          this.userDetail = res.data
        })
    },
    miniShare() {
      wx.miniProgram.postMessage({
        data: {
          type: 'share',
          shareImage: this.shareParams.miniImage,
          shareTitle: this.shareParams.title,
          shareUrl: this.shareParams.path,
        },
      })
    },
    openRule(isTotalRule) {
      this.curRule = isTotalRule ? this.AI.shopStyle.ruleContent : this.curGoods.saleRule
      this.isRuleShow = true
    },
    close() {
      this.isItemConfirmShow = false
      this.isRenewPayTypeShow = false
      this.isItemSelectShow = false
      this.itemIndex = undefined;
      this.isZhimaxianxiang = false
      this.isHasActivedCardShow = false
      this.isCombinedBuyShow = false
    },
    setTabIndex(v) {
      this.tabIndex = v
      this.getGoodStatus()
      this.setScroll().then(() => {
        window.scrollTo({
          left: 0,
          top: this.topArr[0] - 5,
          behavior: 'smooth',
        });
      })
    },
    bindScroll() {
      this.getGoodStatus()
      this.setScroll()
    },
    toGroup(el, index) {
      const refBox = this.$refs.refContent;
      this.groupIndex = index
      const ref = this.$refs[el]
      console.log(refBox.offsetTop, ref[0].offsetTop, this.navHeight, this.groupHeight)
      this.isClickScroll = true
      clearTimeout(this.timer2);
      this.timer2 = setTimeout(() => {
        this.isClickScroll = false
      }, 1000)
      window.scrollTo({
        left: 0,
        top: refBox.offsetTop + ref[0].offsetTop - (this.barHeight + this.navHeight + this.groupHeight),
        behavior: 'smooth',
      })
    },
    setScroll() {
      return new Promise((resolve, reject) => {
        window.removeEventListener('scroll', this.handleScroll, true)
        clearTimeout(this.timer);
        this.topArr = []
        this.timer = setTimeout(() => {
          try {
            this.navHeight = this.$refs.nav ? this.$refs.nav.clientHeight : 0
            this.groupHeight = this.$refs.groupBox ? this.$refs.groupBox.clientHeight + 5 : 0;
            const gb = this.$refs.groupBg;
            this.curGoods.groupInfos.map((group, index) => {
              let top = this.$refs.refContent.offsetTop + this.$refs[`group${group.id}`][0].offsetTop - (this.barHeight + this.navHeight + this.groupHeight)
              this.topArr.push(top)
              if (gb) {
                let left = 0;
                if (index > 0) {
                  const id = this.curGoods.groupInfos[index - 1].id;
                  const dom = this.$refs['item' + id][0]; console.dir(dom)
                  left = dom.offsetLeft + dom.offsetWidth;
                }
                group.groupbox_left = left;
              }
            })
            console.log(this.topArr);
            if (this.curGoods.showGroupMenu && this.curGoods.groupInfos.length > 1) {
              window.addEventListener('scroll', this.handleScroll, true)
            }
            resolve();
          } catch (error) {
            this.errLen = this.errLen ? this.errLen + 1 : 1;
            if (this.errLen < 10) {
              console.log(error)
              return this.setScroll();
            } else {
              reject();
            }
          }
        }, 200)
      })
    },
    handleScroll(e) {
      if (e.target.nodeName !== '#document' || this.isClickScroll) return
      let top = document.documentElement.scrollTop || document.body.scrollTop
      for (let i = 0; i < this.topArr.length; i++) {
        if (top < this.topArr[i + 1]) {
          this.groupIndex = i
          break
        } else {
          this.groupIndex = this.topArr.length - 1
        }
      }
    },
    hexToRgba(hex, opacity) {
      if (!hex) return ''
      return `rgba(${parseInt('0x' + hex.slice(1, 3))}, ${parseInt('0x' + hex.slice(3, 5))}, ${parseInt('0x' + hex.slice(5, 7))}, ${opacity})`
    },
    recommendedClose() {
      this.getGoodStatus()
    },
    addressChange(obj) {
      this.address = obj
    },
    //门店弹出窗口返回的数据
    choseStorePop(val, param) {
      console.log(val, param)
      this.venueSelectShow = false
      if (param) {
        this.isItemSelectShow = false
        this.perUseVenueId = param.venueId[0]
        this.buyCard(this.buyGoods)
      }
    },
    // 惊喜商品
    getSurpriseList() {
      let sortList = [4332, 4333, 4331], map = {};
      this.killGoods.map(item => {
        if (item.goodsType == 12 && this.goodsStatusMap[item.id] == 'CAN_BUY') {
          map[item.id] = item
        }
      })
      let sortedArray = sortList.map(id => map[id] ? map[id] : '').filter(i => i != '');
      if (sortedArray.length) {
        this.surpGood = sortedArray[0]
        this.surpriseShow = true
      }
    },
    // 惊喜商品购买
    goSupBuy(item) {
      this.surpriseShow = false
      this.buyCard(item, 'must_buy')
      this.countPoint('410', item.id, '40062')
    },
  },
}
</script>
<style lang="less">
.pub-coner {
  &::before {
    content: '';
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    background: inherit;
    border-radius: inherit;
    z-index: -1;
  }

  &::after {
    content: '';
    position: absolute;
    left: var(--left, 20px);
    top: 100%;
    background: #6F86D6;
    width: 20px;
    height: 20px;
    transform: skew(0, -20deg) rotate(-15deg);
    margin-top: -16px;
    z-index: -2;
  }
}
</style>
<style lang="less" scoped>
.marketing-template-main {
  min-height: 100vh;
  background: #fb7a37;
  padding-bottom: 80px;

  div {
    box-sizing: border-box;
  }

  img {
    width: 100%;
    vertical-align: top;
  }

  .headbg {
    position: relative;
    width: 100%;
    min-height: 630px;

    .top-button {
      position: absolute;
      right: 0;
      top: 210px;
      width: 88px;
      z-index: 99;

      .icon {
        width: 88px;
        height: 88px;
        object-fit: contain;
        margin-bottom: 32px;
      }

      .button {
        line-height: 56px;
        background: rgba(36, 40, 49, 0.3);
        border-radius: 40px 0 0 40px;
        color: #fff;
        width: 100%;
        text-align: center;
        margin-bottom: 32px;
        padding-left: 6px;
      }
    }

    .float-new {
      position: absolute;
      right: 4px;
      top: 419px;
      height: 124px;
      width: 124px;
      z-index: 100;
    }

    .float-icon {
      position: absolute;
      right: 6px;
      bottom: 92px;
      width: 120px;
      z-index: 99;
    }

    .float-voucher {
      position: absolute;
      left: 6px;
      bottom: 94px;
      width: 120px;
      z-index: 99;
    }
  }

  .invite-block {
    width: 686px;
    height: 104px;
    margin: 30px 32px 0;
    background: url(https://img.chaolu.com.cn/ACT/temp-activity/student-mall/main_bg_invite.png);
    background-size: 100% 100%;
    padding: 0 36px;
    color: #eeeeee;

    .invite-head {
      width: 64px;
      height: 64px;
      border-radius: 50%;
      overflow: hidden;
      background: #f5f5f5;
      flex-shrink: 0;
      margin-right: 20px;

      img {
        height: 100%;
        object-fit: cover;
      }
    }
  }

  .banner-dj {
    margin: 0 32px 30px;
    height: 156px;
  }

  .banner-block {
    height: 96px;
    margin: 0 32px;
  }

  .fh {
    height: 100%;
    object-fit: cover;
  }

  .fl-block {
    margin: 38px 29px 0;
    position: relative;
    background: url(https://img.chaolu.com.cn/ACT/double11-2024/main/flbg.png);
    background-size: 100% 100%;
    padding: 216px 27px 0;
    height: 736px;
    position: relative;

    .rule-btn {
      position: absolute;
      width: 40px;
      height: 68px;
      right: 4px;
      top: 58px;
    }

    .fl-model {
      background: #FFFFFF;
      border-radius: 16px;
      height: 238px;
      padding: 0 24px;
      display: flex;
      align-items: center;
      margin-bottom: 14px;
      position: relative;
      border-radius: 16px;
      overflow: hidden;

      .lb {
        position: absolute;
        left: 0px;
        top: 0px;
        width: 100%;
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        background-color: rgba(0, 0, 0, 0.6);
        z-index: 10;
        img {
          width: 144px;
          height: 144px;
        }
      }

      .fl-model-pic {
        width: 190px;
        height: 190px;
        margin-right: 20px;
        flex-shrink: 0;
        img {
          width: 100%;
          height: 100%;
        }
      }

      .fl-model-info {
        flex: 1;

        .fline1 {
          font-size: 26px;
          font-weight: 600;
        }

        .line2>span {
          color: #B12E1D;
          font-size: 20px;
          background: linear-gradient(90deg, #FFE8B5 0%, #FFD7C3 100%);
          border-radius: 4px;
          margin-right: 10px;
          padding: 4px 8px;
        }

        .fl-sub {
          width: 380px;
          height: 63px;
          margin-top: 18px;
          background: url(https://img.chaolu.com.cn/ACT/double11-2024/main/flsub.png);
          background-size: 100% 100%;
          font-size: 22px;
          font-family: BebasNeueBold;
          color: #fff;
          line-height: 1;
          padding-top: 12px;
          padding-left: 16px;
          .th{
            margin-right: 0px;
            position: relative;
            top: -2px;
          }
          &.nobuy {
            // background: url(https://img.chaolu.com.cn/ACT/88buy-preheat-2024/making/nobuy-btn.png) top/100% auto no-repeat;
          }

          &.endbtn {
            background-image: url(https://img.chaolu.com.cn/ACT/double11-2024/main/flsub2.png);
          }


          .num1 {
            font-size: 44px;
          }
          .num2 {
            margin-left: 4px;
          }
        }
      }
    }
  }

  .kill-block {
    margin: 38px 29px 0;
    position: relative;
    background-size: 100% auto;


    .rule-btn {
      position: absolute;
      width: 40px;
      height: 68px;
      right: 4px;
      top: 75px;
    }

    .kill-item-box {
      display: flex;
      justify-content: space-between;
      flex-wrap: wrap;
      padding: 0 29px 30px;
      position: relative;
      background: url(https://img.chaolu.com.cn/ACT/double11-2024/main/bg2_02.png);
      background-size: 100% 100%;
    }

    .kill-item {
      width: 312px;
      height: 413px;
      background: #fff;
      border-radius: 12px;
      padding: 24px;
      position: relative;
      margin-top: 14px;

      &:nth-child(-n+2) {
        margin-top: 0;
      }

      img {
        border-radius: 12px;
        width: 264px;
        height: 160px;
      }

      .line1 {
        margin-top: 20px;
        font-size: 28px;
        font-weight: 600;
      }

      .line2>span {
        color: #B12E1D;
        font-size: 20px;
        background: linear-gradient(90deg, #FFE8B5 0%, #FFD7C3 100%);
        border-radius: 4px;
        margin-right: 10px;
        padding: 4px 8px;
      }

      .btn {
        width: 272px;
        height: 63px;
        margin-top: 18px;
        background: url(https://img.chaolu.com.cn/ACT/88buy-preheat-2024/making/buy-btn.png?v=1) top/100% auto no-repeat;
        font-size: 22px;
        font-family: BebasNeueBold;
        color: #fff;
        line-height: 1;
        padding-top: 12px;
        padding-left: 16px;

        &.nobuy {
          background: url(https://img.chaolu.com.cn/ACT/88buy-preheat-2024/making/nobuy-btn.png) top/100% auto no-repeat;
        }

        &.endbtn {
          background: url(https://img.chaolu.com.cn/ACT/88buy-preheat-2024/making/end-btn.png) top/100% auto no-repeat;
        }


        .num1 {
          font-size: 44px;
        }
      }

      .limit-buy {
        position: absolute;
        left: 0px;
        top: 0px;
        width: 312px;
        height: 413px;
        border-radius: 12px;
        display: flex;
        align-items: center;
        justify-content: center;
        background-color: rgba(0, 0, 0, 0.5);

        img {
          width: 144px;
          height: 144px;
        }
      }
    }
  }

  .nav-title {
    margin: 57px 0 0;
    position: relative;
    z-index: 9;
  }

  .template-content {
    margin: -36px 32px 0;
    position: relative;
    padding: 44px 24px 1px;
    background: #FF9D3D;
    box-shadow: 0px 0px 32px 0px #FFF4C1 inset;
    border-radius: 50px 50px 40px 40px;

    .ad-group {
      .ad-item {
        width: 100%;
        margin: 0 0 32px;
      }
    }

    .nav {
      display: flex;
      flex-wrap: wrap;
      position: relative;
      padding: 24px 0;
      // height: 148px;
      z-index: 9;
      width: 638px;
      margin: 0 auto;

      .tm {
        width: 202px;
        background: linear-gradient(180deg, #ffffff 0%, #ffe9e3 100%);
        border-radius: 12px;
        height: 100px;
        padding-left: 26px;
        color: #383e43;
        position: relative;
        overflow: hidden;
        margin-right: 16px;
        margin-top: 16px;

        &:nth-child(-n+3) {
          margin-top: 0;
        }

        &:nth-child(3n+3) {
          margin-right: 0;
        }

        &.small {
          max-width: 218px;
          min-width: 216px;
        }

        &.active {
          color: #9b270c;
          background: linear-gradient(180deg, #fffed8 0%, #ffe293 100%);
        }

        .ii {
          transform: scale(0.8);
          transform-origin: 0 50%;
          white-space: nowrap;
        }
      }
    }

    .nav-sticky /deep/.van-sticky--fixed {
      background: #FF3F1A;
    }

    .title-line {
      width: 100%;
      color: #fff;
      padding: 0px 0 20px;
      position: relative;
      z-index: 9;

      .arrow {
        width: 21px;
      }

      .ss {
        margin-right: 4px;
      }

      .triangle {
        width: 21px;
        margin-left: 10px;
      }
    }

    .subfield-name {
      font-size: 34px;
      color: #FFFAD7;
      text-shadow: -2px -2px 0 #F83F1A,
        2px -2px 0 #F83F1A,
        -2px 2px 0 #F83F1A,
        2px 2px 0 #F83F1A;
    }

    .group-bar {
      height: 104px;
      width: 638px;
      margin: 0 auto;
      padding-bottom: 24px;
      position: relative;
      z-index: 99;
      overflow: hidden;

      &::after {
        position: absolute;
        border-radius: 20px;
        background: rgba(255, 255, 255, 0.2);
        content: '';
        left: 0;
        right: 0;
        top: 0;
        height: 80px;
        z-index: 98;
      }

      .group-bg {
        width: 100%;
        display: flex;
        overflow: scroll;
        padding: 8px;
        position: relative;
        -webkit-overflow-scrolling: touch;
        z-index: 99;
      }

      .item {
        width: 20%;
        color: #fff;
        border-radius: 20px;
        padding: 12px 0;
        height: 64px;

        &.active {
          background: linear-gradient(0deg, #FFFFFF 0%, #FFE9E3 100%);
          color: #F9624E;
        }
      }
    }

    .tabar-sticky /deep/.van-sticky--fixed {
      background: #FF3F1A;

      &::before {
        content: '';
        position: absolute;
        bottom: 100%;
        height: 1px;
        left: 0;
        width: 100%;
        background: inherit;
      }
    }

    .good-group {
      // border-radius: 0px 0px 40px 40px;
      // padding: 24px;
      margin-bottom: 40px;
      min-height: 280px;
      position: relative;


      &.only-img {
        background: none;
        padding-left: 0;
        padding-right: 0;
      }

      .two-img {
        width: 50%;
      }

      .three-img {
        width: 33.33%;
      }
    }

    .group-name {
      color: #fff;
      padding: 0 0 0 18px;
      position: relative;
      margin-bottom: 20px;

      &:first-of-type {
        margin-top: 12px;
      }

      &::before {
        content: '';
        position: absolute;
        left: 0;
        width: 8px;
        height: 28px;
        background: linear-gradient(0deg, #FFFED8 0%, #FFE293 100%);
        border-radius: 4px;
      }
    }
  }

  .end {
    text-align: center;
    color: #fff;
    position: relative;
    margin-top: 40px;

    &::after,

    &::before {
      content: '';
      vertical-align: middle;
      display: inline-block;
      width: 60px;
      height: 1px;
      background: #fff;
    }

    &::after {
      margin: -2px 0 0 29px;
    }

    &::before {
      margin: -2px 29px 0 0;
    }
  }

  .pub-close {
    position: absolute;
    top: 100%;
    margin-top: 60px;
    text-align: center;
    width: 60px;
    height: 60px;
    left: 50%;
    margin-left: -30px;

    img {
      height: 100%;
    }
  }

  .pop-upgrade {
    border-radius: 40px;
    width: 610px;
    padding: 43px 30px 36px;
    overflow: visible;
    transform-style: preserve-3d;

    &::before {
      content: '';
      position: absolute;
      left: 0;
      top: 0;
      right: 0;
      height: 220px;
      background: linear-gradient(0deg, #FFFFFF 0%, #FFF5DD 100%);
      border-radius: 40px 40px 0px 0px;
      z-index: -1;
    }

    .utitle {
      width: 331px;
      height: 52px;
      margin: 0 auto 40px;

      img {
        height: 100%;
      }
    }

    .process {
      display: flex;
      align-items: center;
      justify-content: center;
      margin: 0 8px;

      .cfrom,
      .cto {
        width: 220px;
        height: 150px;

        img {
          height: 100%;
          object-fit: contain;
        }
      }

      .cjt {
        width: 107px;
        height: 94px;
        position: relative;
        left: -10px;
      }
    }

    .utext {
      text-align: center;
      font-weight: 600;
      font-size: 24px;
      color: #000000;
      line-height: 1;
      margin: 35px 0 57px;

      .ap {
        color: #F03C18;
        font-size: 46px;
        font-weight: normal;
      }
    }

    .uinfo {
      background: #FFF5F0;
      border-radius: 16px;
      border: 3px solid #FF5A00;
      padding: 35px 31px;

      .uinfo-1,
      .uinfo-2 {
        display: flex;
        justify-content: space-between;
        align-items: center;
        line-height: 1;
      }

      .uinfo-1 {
        font-size: 28px;
        font-weight: 600;
        margin-bottom: 28px;

        .l1 {
          color: #000000;
          position: relative;

          .uc {
            position: absolute;
            left: 100%;
            top: 0;
            margin-left: 6px;
            margin-top: -54px;
            // padding: 0 12px;
            height: 50px;
            border-radius: 10px;
            font-weight: bold;
            font-size: 30px;
            color: #FFFFFF;
            line-height: 1;
            display: flex;
            align-items: center;
            justify-content: center;
            z-index: 1;
            width: 100px;
            background: linear-gradient(0deg, #6F86D6 0%, #48C6EF 100%);
          }
        }

        .r1 {
          color: #F03C18;
        }
      }

      .uinfo-2 {
        font-size: 20px;

        .l2 {
          color: #000000;
        }

        .r2 {
          color: #6C727A;
          text-decoration-line: line-through;
        }
      }
    }

    .u-subline {
      display: flex;
      justify-content: space-between;
      margin-top: 64px;

      .usm {
        width: 265px;
        height: 96px;
        border-radius: 8px;
        text-align: center;
        padding-top: 22px;
        line-height: 1;
        position: relative;
      }

      .usub1 {
        color: #fff;
        background: #FF5A00;
      }

      .usub2 {
        color: #000;
        background: #FFDE00;
      }

      .usm-1 {
        font-weight: 600;
        font-size: 30px;
        margin-bottom: 9px;
      }

      .usm-2 {
        font-size: 20px;
      }

      .uc2 {
        height: 36px;
        line-height: 34px;

        border-radius: 8px;
        font-weight: 600;
        font-size: 22px;
        color: #FFFFFF;
        padding: 0 12px;
        position: absolute;
        top: 0;
        margin-top: -38px;
        right: 0;
        z-index: 99;

        &::before {
          content: '';
          position: absolute;
          bottom: -10px;
          left: 50%;
          width: 16px;
          height: 16px;
          background: #6F86D6;
          border: 2px solid #fff;
          transform: translateX(-50%) rotate(45deg);
          z-index: -2;
        }

        &::after {
          content: '';
          position: absolute;
          left: 0;
          right: 0;
          top: 0;
          bottom: 0;
          background: linear-gradient(0deg, #5189DD 0%, #1E6BEB 100%);
          border-radius: inherit;
          z-index: -1;
        }
      }
    }
  }

  .pop-city-c {
    overflow: visible;
    padding-top: 200px;
    border-radius: 24px 24px 0px 0px;

    .icon {
      width: 273px;
      height: 260px;
      position: absolute;
      top: -100px;
      left: 50%;
      transform: translateX(-50%);
    }

    .tips {
      padding: 0px 100px 110px;
      font-size: 28px;
      line-height: 2;
      text-align: center;

      span {
        color: #F03C18;
        font-size: 36px;
        font-weight: bold;
        margin: 0 10px;
      }
    }

    .btn {
      padding: 26px 32px;
      text-align: center;
      line-height: 92px;
      height: 92px;
      font-size: 32px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      box-shadow: 0px 0px 1px 0px rgba(0, 0, 0, 0.2);
      box-sizing: content-box;

      >div:first-child {
        border: 1px solid #EEEEEE;
        border-radius: 8px;
        width: 212px;
      }

      >div:last-child {
        background-color: #FFDE00;
        border: 1px solid #FFDE00;
        border-radius: 8px;
        width: 442px;
        font-weight: 600;
      }
    }
  }

  .pop-change {
    border-radius: 24px 24px 0px 0px;

    .p-title {
      font-size: 28px;
      line-height: 96px;
      height: 98px;
      border-bottom: 1px solid #EEEEEE;
      text-align: center;
      font-weight: 600;
    }

    .city-list {
      padding: 35px 32px 78px;

      p {
        padding: 0px 33px;
        font-size: 32px;
        background-color: #F5F5F5;
        border-radius: 8px;
        height: 128px;
        line-height: 128px;

        &.act {
          border: 2px solid #232831;
        }
      }

      p+p {
        margin-top: 32px;
      }
    }

    .btn {
      box-shadow: 0px 0px 1px 0px rgba(0, 0, 0, 0.2);
      box-sizing: content-box;
      padding: 27px 32px;
      text-align: center;

      >p:first-child {
        font-size: 22px;
        color: #68413C;
        margin-bottom: 20px;
      }

      >p:last-child {
        background-color: #FFDE00;
        border: 1px solid #FFDE00;
        border-radius: 8px;
        height: 92px;
        font-weight: 600;
        line-height: 92px;
      }
    }
  }


  .pop-rule {
    border-radius: 24px 24px 0 0;
    color: #242831;

    .pop-rule-title {
      height: 140px;
      text-align: center;
      line-height: 130px;
      position: relative;
      background: linear-gradient(0deg, #FFFFFF 0%, #FFD6C0 100%);

      .pop-rule-close {
        position: absolute;
        right: 32px;
        top: 38px;
        width: 44px;
        height: 44px;
        line-height: 44px;
        border-radius: 50%;
        background: rgba(39, 40, 49, 0.2);
      }
    }

    .pop-rule-content {
      padding: 0 52px;
      max-height: 600px;
      overflow: auto;
      padding-bottom: 60px;
      white-space: pre-line;
    }
  }

  .pop-sure {
    width: 590px;
    border-radius: 12px;
    padding: 50px 40px;

    .sure-title {
      color: #242831;
      text-align: center;
    }

    .sure-content {
      color: #3C454E;
      line-height: 1.6;
      margin: 36px 0 48px;
      text-align: center;

      span {
        color: #F03C18;
      }
    }

    .sure-sub {
      border-radius: 8px;
      background: #FFDE00;
      color: #000;
      line-height: 96px;
      text-align: center;
    }
  }

  .pop-surprise {
    background-color: transparent;
    overflow: visible;

    .s-box {
      width: 630px;
      height: 705px;
      position: relative;

      .close2 {
        width: 60px;
        height: 60px;
        position: absolute;
        right: 10px;
        top: 47px;
        z-index: 100;
      }

      .s-item {
        width: 610px;
        height: 705px;
        position: relative;
        margin: 0 auto;
        background-size: 100% 100%;

        .btn {
          width: 550px;
          height: 96px;
          line-height: 96px;
          text-align: center;
          background-color: #FFDE00;
          border-radius: 8px;
          font-size: 32px;
          font-weight: 600;
          position: absolute;
          bottom: 36px;
          left: 30px;
        }
      }

    }
  }

  .pop-item {
    border-radius: 24px 24px 0 0;
    color: #242831;

    .buy-title {
      border-bottom: 1px solid #EEEEEE;
      height: 97px;
      line-height: 97px;
      padding: 0 32px;
      text-align: center;
      position: relative;
    }

    .buy-close {
      position: absolute;
      right: 32px;
      top: 50%;
      transform: translateY(-50%);

    }

    .cbox {
      padding: 37px 32px 0;
      border-bottom: 1px solid #eee
    }

    .buy-tips {
      color: #9AA1A9;
      margin: 4px 0 22px;
    }

    .goods-line {
      display: flex;
      margin: 22px 0 60px;

      .goods-img {
        width: 256px;

        img {
          width: 100%;
          border-radius: 16px;
          display: block;
        }
      }

      .goods-info {
        flex: 1;
        margin-left: 30px;

        .goods-name {
          color: #242831;
        }

        .time {
          color: #242831;
          margin: 12px 0 20px;
          line-height: 1;
        }

        .labels {
          margin: 8px 0 15px;

          .label {
            height: 30px;
            background: linear-gradient(90deg, #FFE8B5 0%, #FFD7C3 100%);
            padding: 0 13px;
            margin: 0 12px 0 0;
            color: #B12E1D;
            border-radius: 4px;
          }
        }

        .price-line {
          margin-top: 8px;
          line-height: 1;

          .dl {
            color: #EE4832;
            margin-right: 4px;
          }

          .price {
            color: #EE4832;
            margin-right: 9px;
            position: relative;
            top: 2px;
          }

          .ori-price {
            text-decoration: line-through;
            color: #6C727A;
          }
        }
      }
    }

    .city-list {
      padding: 0 0 32px;

      .city-model {
        margin-top: 24px;
        height: 128px;
        background: #F5F5F5;
        border-radius: 8px;
        padding: 0 32px;

        &.disabled {
          color: #9AA1A9;
        }

        &.active {
          border: 4px solid #242831;

          .checkbox {
            width: 48px;
            height: 48px;
            background: url(https://chaolucoachwebstatic.oss-cn-hangzhou.aliyuncs.com/static/img/spring2023/check.png);
            background-size: 100% 100%;
            border: none;
            margin: 0;
          }
        }

        .checkbox {
          width: 32px;
          height: 32px;
          border: 1px solid #DDD;
          border-radius: 50%;
          margin-right: 8px;
        }

        .has-buy {
          width: 102px;
        }
      }


    }

    .next {
      line-height: 96px;
      background: #FFDE00;
      border-radius: 8px;
      margin: 34px 32px 0;
      text-align: center;
      margin-bottom: 34px;

      &.grey {
        filter: grayscale(1);
      }
    }
  }

  .error-box {
    width: 630px;
    background: #ffffff;
    border-radius: 12px;
    margin: 0 auto;
    padding: 0 40px 48px;

    h4 {
      text-align: center;
      padding-top: 40px;
    }

    p {
      margin: 32px auto 56px;
      width: 510px;
      color: #3c454e;
      line-height: 44px;
    }

    .btn {
      margin: 0 auto;
      width: 510px;
      height: 96px;
      background: #ffde00;
      border-radius: 8px;
    }

    span {
      color: #f03c18;
    }
  }



  .canvascss {
    width: 300PX;
    height: 539PX;
    margin: 0 auto;
    box-sizing: border-box;

    .canvascss_bg {
      width: 100%;
      height: 100%;
    }

    .canvascss_code {
      position: absolute;
      left: 60PX;
      bottom: 7PX;
      width: 66PX;
      height: 71PX;
      background: #fff;
      z-index: 99;
      border-radius: 5PX;
      overflow: hidden;
      border: 1PX solid #E1E1E1;

      img {
        width: 100%;
        height: 65PX;
        position: relative;
        top: -2PX;
      }

      .canvascss_code_text {
        position: absolute;
        bottom: 4PX;
        left: 0;
        right: 0;
        text-align: center;
        line-height: 1;
        font-size: 8PX;
        color: #000000;
      }
    }

    .canvascss_info {
      position: absolute;
      left: 133PX;
      bottom: 26PX;
      padding: 3PX;
      width: 108PX;
      height: 51PX;
      overflow: hidden;
      align-items: center;
      display: flex;

      .canvascss_head {
        width: 45PX;
        height: 45PX;
        border-radius: 7PX;
        margin-right: 6PX;
      }

      .canvascss_full {
        font-weight: bold;
        font-size: 11PX;
        color: #000000;
        flex: 1;

        .canvascss_name {
          line-height: 1.3;
        }
      }
    }
  }

  .red {
    color: #ee4832;
  }
}

/deep/ .red {
  color: #ff6e00;
}
</style>
