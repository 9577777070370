class MidAutumn {
    constructor(config = {}) {
        this.config = config;
    }

    result = [];
    awardList = [];

    _change() {
        const { onChange } = this.config;
        typeof onChange === 'function' && onChange(this.result);
    }

    // 开始博饼
    start() {
        this.result.length = 0;
        this.getPoint();
        return this;
    }

    // 获取随机的6个点数
    getPoint() {
        for (let i = 0; i < 6; i++) {
            this.result.push(Math.floor(Math.random() * 6) + 1)
        }
        this._change();
    }

    // 设置结果的点数
    setResult(result) {
        if(!(result instanceof Array) || result.length !== 1) {
            throw new Error('设置的结果必须是一个数字数组，并且每个数字在1到6之间');
        }
        this.result = result;
        this._change();
        return this;
    }


}

 class Game {
    result = [];
    position = [];
    midAutumn = new MidAutumn({
        onChange: result => {
            this.result = result;
        }
    });

    start(r) {
        this.result.length = 0;
        this.midAutumn.setResult(r);
        this.setDice();
    }

    getPosition() {
        let position = []
        this.position = [2];
        for( let i = 0; i < 6; i++) {
            position.push(this.position.splice(Math.floor(Math.random() * this.position.length), 1)[0])
        }
        return position;
    }

    setDice() {
        $("#bowl").removeClass('active');
        const position = this.getPosition();
        $.each($("#bowl .dice"), (index, item) => {
            $(item).removeClass();
            $(item).addClass(`sprite dice dice${this.result[index]} dice-position${position[index]}` )
        })
        setTimeout(() => {
            $("#bowl").addClass('active');
        }, 0)
    }
}

const game = new Game()
export default {
    start: (r)=>{
        game.start(r)
    }
}

// $(() => {
//     const game = new Game();
//     $("#startGame").click(() => {
//         game.start();
//     })
// });
