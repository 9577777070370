<template>
    <div>
        <!-- 沉浸式头部 -->
        <nav-bar header-title="双11新人热卖专区 " backColor="#fff" :show-back="true" :header-back="newAppBack"
            @setBarHeight="setBarHeight" />
        <!-- 主会场 -->
        <div class="marketing-template-new">
            <div class="headbg">

                <div class="top-button">
                    <template v-if="AI.shopStyle.ruleIconImg">
                        <img class="icon" :src="AI.shopStyle.ruleIconImg" @click="openRule" alt="" />
                    </template>
                    <template v-else>
                        <div class="button f24" :style="`background: ${AI.shopStyle.ruleIconColor}`" @click="openRule">
                            规则
                        </div>
                    </template>
                </div>
                <count-down v-if="AI.shopStyle.headerCountdown" :timeObj="AI" :status="activityStatus"
                    @timeFinish="timeFinish"></count-down>
            </div>

            <div v-for="item in list" class="goods" :style="{ backgroundImage: 'url(' + item.bg + ')' }">
                <div class="sub puhui" :class="{ dis: goodsStatusMap[item.id] != 'CAN_BUY' }" @click="buyCard(item)">
                    {{ statusJson[goodsStatusMap[item.id]] }}
                </div>
            </div>


            <van-popup v-model="cityConfirmShow" position="bottom" safe-area-inset-bottom class="pop-city-c" closeable>
                <img class="icon" src="https://img.chaolu.com.cn/ACT/88buy-preheat-2024/making/tips.png" alt="" />
                <p class="tips">
                    您当前购买的产品，仅限<span v-show="cityName">{{ cityName }}</span>所有门店使用，请确认后再付款！
                </p>
                <div class="btn">
                    <div @click="changeCityShowFun">切换城市</div>
                    <div @click="citySure">确认</div>
                </div>
            </van-popup>
            <van-popup v-model="cityChangeShow" position="bottom" safe-area-inset-bottom class="pop-change" closeable
                @close="cityChangeClose">
                <p class="p-title">切换城市</p>
                <div class="city-list">
                    <p v-for="(item, key) in cityJson" :class="key == unQueryCityId ? 'act' : ''"
                        @click="unQueryCityId = key">{{
                            item }}
                    </p>
                </div>
                <div class="btn">
                    <p>选择指定城市，卡仅限所选定的城市使用。</p>
                    <p @click="comfrimcCityFun">确认</p>
                </div>
            </van-popup>

            <!-- 规则 弹窗 -->
            <van-popup v-model="isRuleShow" position="bottom" safe-area-inset-bottom class="pop-rule">
                <div class="pop-rule-title f32 fw6">
                    活动规则
                    <van-icon name="cross" class="pop-rule-close f28" color="#fff" @click="isRuleShow = false" />
                </div>
                <div class="pop-rule-content f26">
                    {{ curRule }}
                </div>
            </van-popup>
            <van-popup v-model="isItemSelectShow" @click-overlay="close" position="bottom" safe-area-inset-bottom
                class="pop-item">
                <div class="buy-title f32 fw6">
                    确认下单
                    <van-icon name="cross" class="buy-close f36" @click="close" />
                </div>
                <div class="cbox" v-if="isItemSelectShow">
                    <div class="f28 fw6">购买商品</div>
                    <div class="goods-line">
                        <div class="goods-img">
                            <img :src="buyGoods.goodsImg" />
                        </div>
                        <div class="goods-info">
                            <div class="goods-name f34 fw6">{{ buyGoods.goodsName }}</div>
                            <div class="time f22" v-if="buyGoods.hint">{{ buyGoods.hint }}</div>
                            <div class="labels row-start-center" v-if="buyGoods.productTags">
                                <div class="label row-center-center f20 fw6"
                                    v-for="item, index in buyGoods.productTags.split(',')" :key="index">{{
                                        item }}</div>
                            </div>
                            <div class="price-line row-start-end">
                                <span class="dl f22 fw6">¥</span>
                                <span class="price f32 fw6">{{ itemIndex === undefined ? buyGoods.salePrice :
                                    buyGoods.itemInfos[itemIndex].salePrice }}</span>
                                <span class="ori-price f20">¥{{ itemIndex === undefined ? buyGoods.originPrice :
                                    buyGoods.itemInfos[itemIndex].originPrice }}</span>
                            </div>
                        </div>
                    </div>
                    <template v-if="itemList.length > 1">
                        <div class="f28 fw6">{{ buyGoods.goodsItemExp }}</div>
                        <div class="buy-tips f24">{{ buyGoods.notice }}</div>
                        <div class="city-list">
                            <div v-for="(item, index) in itemList" :key="index"
                                :class="['city-model', 'row-between-center', 'fw6', itemIndex === index ? 'active' : '', itemStatusMap[item.id] === 'CAN_BUY' ? '' : 'disabled']"
                                @click="chooseItem(index, itemStatusMap[item.id], item)">
                                <p>{{ item.itemName }}&nbsp;&nbsp;
                                    <span
                                        v-if="itemPriceStatusMap[item.id] && itemPriceStatusMap[item.id].showAfterVoucher"
                                        class="red f22 fw6">券后价</span>
                                    <span class="red f24 fw6">¥</span><span class="red">{{
                                        itemPriceStatusMap[item.id] && itemPriceStatusMap[item.id].discountPrice ||
                                        item.salePrice }}</span>
                                </p>
                                <div v-if="itemStatusMap[item.id] === 'CAN_BUY'" class="checkbox"></div>
                                <img v-else src="https://img.chaolu.com.cn/ACT/marketing/soldout.png" alt=""
                                    class="has-buy">
                            </div>
                        </div>
                    </template>
                    <AddAddress v-if="buyGoods.goodsType === 22 && !buyGoods.showPeriphery" @change="addressChange"
                        color="#FFDE00" />
                </div>
                <div :class="['next', 'fw6', (itemIndex !== undefined || (buyGoods.goodsType === 22 && buyGoods.itemInfos.length === 1)) ? '' : 'grey']"
                    @click="next">立即购买
                    {{ itemIndex === undefined ? '' :
                        `￥${(itemPriceStatusMap[buyGoods.itemInfos[itemIndex].id] &&
                            itemPriceStatusMap[buyGoods.itemInfos[itemIndex].id].discountPrice) ||
                        buyGoods.itemInfos[itemIndex].salePrice}`
                    }}
                </div>
            </van-popup>


            <payPopupCard :showPayPopup.sync="showPayPopup" :pay-data="payData" :pay-url="payUrl"></payPopupCard>

            <EquityVenue :current-city-id="buyItem.extendInfo && buyItem.extendInfo.applyCityIds"
                :popShow.sync="venueSelectShow" :can-use-venue="buyItem.extendInfo && buyItem.extendInfo.applyVenueIds"
                @popfun="choseStorePop" @closefun="closeStorePop" :chose-item="{ areaId: '', venueId: [] }" />
            <van-popup v-model="isItemConfirmShow" position="center" class="pop-sure">
                <div class="sure-title f36 fw6">提示</div>
                <div class="sure-content f24">您当前购买的{{ curGoods.menuName }}为<span v-if="itemIndex != undefined">{{
                    itemList[itemIndex].itemName }}</span>，请确认城市后再付款！</div>
                <div class="sure-sub f32 fw6" @click="nextConfirm">我知道了</div>
            </van-popup>
        </div>
    </div>
</template>
<script>
import { newAppBack, initBack, appPay, appPaySuccess, appOpenWeb, silentAppPay, upgrade, getAppVersion } from '@/lib/appMethod'
import navBar from '@/components/nav-bar/nav-bar'
import countDown from './components/count-down'
import userMixin from '@/mixin/userMixin'
import headerMixin from '@/mixin/headerMixin'
import wx from 'weixin-js-sdk'
import payPopupCard from './components/pay-popup-card'
import AddAddress from './components/address'
import EquityVenue from '@/components/cl-city-venue/equity-venue'

const webPath = `${window.location.origin}/#/superdeer-activity/double11-2024/new-people`
const miniPath = `/pages/webView/index?webUrl=${encodeURIComponent(webPath)}`
const activeId = 10120;

export default {
    components: {
        navBar,
        countDown,
        payPopupCard,
        AddAddress,
        EquityVenue,
    },
    mixins: [userMixin, headerMixin],
    data() {
        return {
            shopId: '', // 活动id
            AI: {
                // 活动总信息
                activityTrailer: {}, // 预告页
                partitions: {},
                shopStyle: {},
            },
            barHeight: 0,
            navHeight: 0,
            groupHeight: 0,
            tabIndex: 0, // 分栏的下标
            groupIndex: 0, // 分组的下标
            shareParams: {
                title: '超鹿双11提前购！定金抢先花~',
                miniImage: 'https://img.chaolu.com.cn/ACT/double11-2024/deposit/mini.png',
                path: '',
                miniProgramType: window.location.origin.indexOf('web.chaolu.com') > -1 ? '0' : '2', // 正式0，开发1，体验2
                url: 'url',
                multiple: '0.95',
                userName: 'gh_7f5b57b6a76e',
            },
            userDetail: {}, //分享的用户信息
            inviteUserInfo: {}, // 邀请人信息
            isShareShow: false,
            isRuleShow: false,
            isItemSelectShow: false, // 规格选择弹窗
            address: {}, // 实体卡情况下 收货地址
            isItemConfirmShow: false, // 规格确认弹窗
            itemIndex: undefined, // 规格下标
            venueSelectShow: false, // 门店的弹窗
            isContinuousShow: false, // 连续包月弹窗
            isZhimaxianxiang: false, // 是否芝麻先享
            isRenewPayTypeShow: false, // 连续包月支付弹窗
            isCombinedBuyShow: false, // 组合购买提示弹窗
            renewPayType: 1,
            isUpgradeShow: false, // 升级提示
            buyGoods: {}, // 当前购买的商品 包含多个规格
            buyItem: {}, // 当前购买的规格
            showPayPopup: false, // 权益卡选择优惠券弹窗
            payData: {}, // 支付参数
            payUrl: '', // 支付回跳链接
            activityStatus: '', // 活动状态
            goodsStatusMap: {}, // 商品对应状态
            goodsPriceStatusMap: {}, // 商品对应的价格状态
            itemStatusMap: {}, // 当前商品下多规格状态
            itemPriceStatusMap: {}, // 当前商品下多规格的价格状态
            curRule: '', // 当前规则 可能是总规则 可能是菜单规则
            isHasActivedCardShow: false, // 已有生效中的卡
            hasActivedTip: '', //
            userExpansionSourceId: '',//获客来源--用拓crm
            cityChangeShow: false,
            list: [],
            cityConfirmShow: false,
            unQueryCityId: '',
            statusJson: {
                'NOT_STARTED': '活动未开始',
                'EXPIRED': '活动已结束',
                'SOLD_OUT': '已售罄',
                'HAVE_BUY': '已购买',
                'CAN_BUY': '马上抢',
                'NOT_BUY': '您不是新用户'
            },
            cityJson: {
                3: '福州市',
                '036652995ac5451fbb6791c3491e1c2d': '厦门市',
                '818447834079563776': '广州市'
            },
        }
    },
    computed: {
        // 当前菜单下的商品 tab
        curGoods() {
            const s = this.AI.partitions.subfieldsInfos;
            let c = s && s.length ? s.slice(-1) : { groupInfos: [], promotionalInfo: {} }
            return c
        },
        // 多规格情况下 规格的list
        itemList() {
            return this.buyGoods.itemInfos ? this.buyGoods.itemInfos : []
        },
        // 根据人群、所在城市 判断是否显示
        showWithCrow() {
            return (good) => {
                // 没有showWithCrow或者有的情况下状态可购买时显示
                return !good.showWithCrow || (good.showWithCrow && this.goodsStatusMap[good.id] === 'CAN_BUY')
            }
        },
        cityName() {
            return this.buyGoods && this.buyGoods.saleCity && this.buyGoods.saleCity.length ? this.buyGoods.saleCity.map(d => {
                return this.cityJson[d]
            }).join(',') : ''
        }
    },
    async created() {
        window.scrollTo(0, 0)
        initBack()
        await this.$getAllInfo(['userId', 'cityId'])

        const q = this.$route.query
        this.countPoint('405', activeId, '40039', this.userId)
        if (this.appTypeStr === "mini") {
            this.countPoint('408', '408', '40054')
        } else {
            this.countPoint('408', '408', '40053')
        }


        // 若是教练邀请 获取教练id
        this.inviteTeacherId = q.teacherId || q.inviteTeacherId
        this.inviteUserId = q.inviteUserId
        this.userExpansionSourceId = q.userExpansionSourceId

        // 来源
        this.from = q.from
        this.queryCityId = q.shopCityId || this.cityId // 区别于mixin里的cityId
        console.log('cid' + this.queryCityId)
        this.unQueryCityId = this.queryCityId
        // 本次商城的id
        this.shopId = activeId

        // 分享的链接
        this.shareParams.path = `${miniPath}&id=${this.shopId}`

        // 推荐弹窗  签到或评价会弹的
        this.isSignInRecommended = q.reason ? true : false


        // 获取活动总信息
        const r = await this.getActivity()
        if (r === '0') return

        // 支付成功回调 小程序购买成功提示
        appPaySuccess().then(() => {
            this.paySuccessCallback()
        })
    },
    mounted() {
        document.addEventListener('visibilitychange', this.vc)
    },
    beforeDestroy() {

        document.removeEventListener('visibilitychange', this.vc)
    },
    methods: {
        vc() {
            if (document.visibilityState === 'visible' && !this.AI.activityTrailer) {
                setTimeout(() => {
                    this.getGoodStatus()
                    // this.getBuy()
                }, 500)
            }
        },
        newAppBack,
        upgrade,
        getActivity() {
            return this.$axios
                .post(`${this.baseURLApp}/activityShopWeb/getShop`, {
                    cityId: this.queryCityId,
                    shopId: this.shopId,
                    userId: this.userId,
                })
                .then((res) => {
                    const d = res.data.partitions.subfieldsInfos;
                    if (d.length) {
                        const img = [
                            'https://img.chaolu.com.cn/ACT/newUserGoods.png',
                            'https://img.chaolu.com.cn/storeAdmin/teacher/2024-10-18/pro/899628672516431872.png',
                            'https://img.chaolu.com.cn/storeAdmin/teacher/2024-10-18/pro/899628969431236608.png'
                        ]
                        this.list = d[d.length - 1].groupInfos[0].goodsInfos.map((d,i) => {
                            return {
                                ...d,
                                bg:img[i]
                            }
                        }); console.log(this.list);
                        this.curRule = d[d.length - 1].saleRule;
                    }
                    this.getGoodStatus()
                    // 活动总信息
                    this.AI = res.data;
                    this.activityStatus = this.AI.diffStartTime > 0 ? 'notin' : this.AI.diffEndTime > 0 ? 'in' : 'end'
                })
                .catch((res) => {
                    this.$toast(res.msg)
                    setTimeout(() => {
                        this.newAppBack()
                    }, 2000)
                    return res.code
                })
        },
        citySure() {
            this.cityConfirmShow = false;
            this.gogoBuy();
        },
        nextConfirm() {
            if (this.itemIndex === undefined) return

            this.isItemConfirmShow = false
            this.isItemSelectShow = false

            this.buyCard(this.buyGoods)
        },
        closeStorePop() {
            this.itemIndex = this.itemIndex === undefined ? undefined : this.itemIndex
        },
        comfrimcCityFun() {
            this.queryCityId = this.unQueryCityId
            this.hasStickyTag = false
            this.cityChangeShow = false
            this.getActivity()
        },
        cityChangeClose() {
            this.cityChangeShow = false
            this.unQueryCityId = this.queryCityId
        },
        changeCityShowFun() {
            this.cityChangeShow = true
            this.cityConfirmShow = false
        },
        closeAnSetFun() { },

        previewFinish() {
            this.getActivity()
        },
        setBarHeight(h) {
            this.barHeight = h || 0
        },
        toPath(path) {
            const p = path.split('?')[0]
            let p1 = path.split('?')[1] ? `&${path.split('?')[1]}` : ''

            if (this.inviteTeacherId) {
                p1 = `${p1}&inviteTeacherId=${this.inviteTeacherId}`
                path = `${path}&inviteTeacherId=${this.inviteTeacherId}`
            }
            if (this.inviteUserId) {
                p1 = `${p1}&inviteUserId=${this.inviteUserId}`
                path = `${path}&inviteUserId=${this.inviteUserId}`
            }

            const hasDomain = p.indexOf('http') > -1
            if (this.appTypeStr === 'mini') {
                wx.miniProgram.navigateTo({
                    url: `/pages/webView/index?webUrl=${hasDomain ? `${p}${p1}` : window.location.origin + '/#' + `${p}${p1}`}`,
                })
            }
            if (this.appTypeStr === 'and') {
                App.intentToActivity(
                    JSON.stringify({
                        className: '.ui.AppWebViewLaunchStandardActivity',
                        data: [
                            {
                                key: 'url',
                                value: path,
                            },
                            {
                                key: 'removeTitle',
                                value: '1',
                            },
                        ],
                    })
                )
            }
            if (this.appTypeStr === 'ios') {
                hasDomain ? appOpenWeb('', path) : this.$router.push(p)
            }
        },
        async openItemSelect() {
            await this.getItemStatus()

            this.itemIndex = this.buyGoods.itemInfos.length > 1 ? undefined : 0
            this.isItemSelectShow = true
        },
        next() {
            // 当前规格下是否包含 指定门店的情况
            const ext = this.buyItem.extendInfo
            if (ext && ext.perOnlyVenue) {
                this.venueSelectShow = true
                return
            }
            // 只要是多规格 就判断是否弹规格确认弹窗
            if (this.buyGoods.itemInfos.length > 1) {
                this.isItemConfirmShow = this.itemIndex != undefined ? true : false
            } else {
                // 实体卡的情况下单独判断
                if (this.buyGoods.goodsType === 22) {
                    if (this.address.userAddressId) {
                        this.itemIndex = 0
                        this.buyCard(this.buyGoods)
                    } else {
                        this.$toast('请填写邮寄地址')
                    }
                }
            }

        },
        chooseItem(i, status, item) {
            if (status !== 'CAN_BUY') return
            this.itemIndex = i
            this.buyItem = item
        },

        checkCanBuy() {
            this.$toast.loading({
                duration: 0,
                forbidClick: true,
                loadingType: 'spinner',
            })
            return this.$axios
                .post(this.baseURLApp + '/activityShopOrder/checkBuy', {
                    goodsId: this.buyGoods.id,
                    goodsItemId: this.buyItem.id,
                    shopId: this.shopId,
                    userId: this.userId,
                })
                .then((r) => {
                    this.$toast.clear()
                    return r.data
                })
                .catch(() => {
                    this.$toast.clear()
                    return 0
                })
        },

        async buyCard(item) {
            // 未登录
            if (!this.userId) {
                this.$store.commit('openLoginPopup', true)
                return
            }
            // this.clickGoodTag = true
            // if (this.clickTimer.length) {
            //   this.closeClickTimer()
            // }
            // item可能来自推荐弹窗组件item.isRecommended:true
            // 推荐的商品可能不是当前分组下的商品 所以goodsStatusMap无法使用
            this.buyGoods = item

            if (this.activityStatus == 'end') {
                this.$toast('活动已结束')
                return
            } else if (this.activityStatus != 'in') {
                this.$toast('活动还没开始哦')
                return
            }
            // 如果由推荐商品的弹窗触发的 必然是能购买的
            if (!item.isRecommended && this.goodsStatusMap[item.id] !== 'CAN_BUY') {
                this.$toast('不符合购买条件')
                return
            }
            const res = await this.checkCanBuy(this.buyGoods)
            if (!res) {
                this.$toast('无法购买')
                return
            }
            this.buyItem = this.itemIndex === undefined ? item.itemInfos[0] : item.itemInfos[this.itemIndex]
            // 规格数量大于1或者为实体卡时 打开规格选项弹窗
            if (((item.itemInfos.length > 1 || [22].includes(item.goodsType)) && this.itemIndex === undefined)) {
                this.openItemSelect();
                return
            }

            // 当前规格下是否包含 指定门店的情况
            const ext = this.buyItem.extendInfo
            if (ext && ext.perOnlyVenue && this.itemIndex === undefined) {
                this.itemIndex = 0
                this.venueSelectShow = true
                return
            }

            this.itemIndex = undefined

            // 城市筛选
            if (item.goodsType == 24) {
                this.cityConfirmShow = true
            } else {
                this.gogoBuy()
            }
        },
        async gogoBuy() {
            this.goPay();
        },

        async goPay() {
            const obj = JSON.parse(JSON.stringify(this.buyGoods))
            this.payAction(obj)
        },
        async payAction() {
            sessionStorage.setItem("goodsPrice", this.buyItem.salePrice);
            const payData = this.getPayParams(); console.log(payData);
            // 如果是教练分享单个商品的情况 支付完回到页面还是只能看到单商品的弹窗 因此回调链接加上goodsId
            const goodsId = this.buyGoods.isRecommended ? this.buyGoods.id : ''
            const url = `${this.shareParams.path}&userId=1&goodsId=${goodsId}&shopCityId=${this.queryCityId}`

            if (this.appTypeStr === 'mini') {
                payData.wxPayType = 'applet'
            } else {
                appPaySuccess().then(() => {
                    this.paySuccessCallback()
                })
            }

            // 弹个选择优惠券的窗
            if (this.buyGoods.canUseVoucher) {
                this.showPayPopup = true
                this.payData = payData
                this.payUrl = url
            } else {
                appPay(payData, url)
            }

            this.close()
        },
        getPayParams() {
            return {
                subject: this.buyItem.productName,
                extendsJson: {
                    appVersion: '2.1.11',
                    shopId: this.shopId,
                    activityShopGoodsId: this.buyGoods.id + '',
                    activityShopGoodsItemId: this.buyItem.id,
                    from: this.from,
                    userAddressId: this.address.userAddressId,
                    perUseVenueId: this.perUseVenueId,
                    prePayOrderNo: this.buyGoods.prePayOrderNo,
                },
                totalAmount: this.buyItem.salePrice + '',
                venueId: '31',
                cityId: this.queryCityId,
                goodsNum: [12, 22].includes(this.buyGoods.goodsType) ? '1' : undefined,
                isApp: '1',
                type: this.buyGoods.goodsType, // 礼品卡12  课包10 实体卡22
                userId: this.userId,
                token: this.token,
                extendsType: '4',
                mouldId: this.buyItem.productId,
                whetherPrivacy: 0,
                originalPriceStr: '',
                title: this.appTypeStr === 'mini' ? '商品名称' : this.buyItem.productName,
            }
        },

        async timeFinish() {
            if (this.activityStatus == 'in') {
                this.activityStatus = 'end'
                this.getGoodStatus()
            } else {
                await this.getActivity()
            }
        },

        // 获取当前菜单tab下商品的id集合 单独获取商品的购买状态 结果用id匹配 {34: 'CAN_BUY'}
        getGoodStatus() {
            const idArr = []

            this.list.map((goods) => {
                idArr.push(goods.id)
            })

            const params = {
                goodsId: idArr,
                shopId: this.shopId,
                userId: this.userId,
            }
            // 获取按钮状态
            this.$axios.post(`${this.baseURLApp}/activityShopWeb/getGoodsStatus`, params).then((res) => {
                this.goodsStatusMap = res.data.goodsStatusMap;
                this.$toast.clear()

            })

            // 获取价格的状态
            this.$axios.post(`${this.baseURLApp}/activityShopWeb/getGoodMaxDiscount`, params).then((res) => {
                this.goodsPriceStatusMap = res.data.goodsDiscountMap;
            })
        },

        // 获取当前购买商品的规格id集合 结果用id匹配 {34: 'CAN_BUY'}
        getItemStatus() {
            this.$toast.loading({
                duration: 0,
                forbidClick: true,
                loadingType: 'spinner',
            })
            const idArr = this.buyGoods.itemInfos.map((item) => item.id)

            const params = {
                goodItems: idArr,
                goodsId: this.buyGoods.id,
                shopId: this.shopId,
                userId: this.userId,
            }
            // 规格价格
            this.$axios.post(`${this.baseURLApp}/activityShopWeb/getGoodItemMaxDiscount`, params).then((res) => {
                this.itemPriceStatusMap = res.data.goodsDiscountMap
            })
            // 规格按钮状态
            return this.$axios.post(`${this.baseURLApp}/activityShopWeb/getGoodsItemStatus`, params).then((res) => {
                this.itemStatusMap = res.data.goodsStatusMap
                this.$toast.clear()
            })
        },
        paySuccessCallback() {
            // this.$toast('支付成功')
            setTimeout(() => {
                this.showPayPopup = false;
                this.perUseVenueId = ''
                this.perVenueName = ''
                this.getGoodStatus()

            }, 500)
        },
        openRule() {
            this.isRuleShow = true
        },
        close() {
            this.isItemConfirmShow = false
            this.isRenewPayTypeShow = false
            this.isItemSelectShow = false
            this.itemIndex = undefined;
            this.isZhimaxianxiang = false
            this.isHasActivedCardShow = false
            this.isCombinedBuyShow = false
        },
        addressChange(obj) {
            this.address = obj
        },
        //门店弹出窗口返回的数据
        choseStorePop(val, param) {
            console.log(val, param)
            this.venueSelectShow = false
            if (param) {
                this.isItemSelectShow = false
                this.perUseVenueId = param.venueId[0]
                this.buyCard(this.buyGoods)
            }
        }
    }
}
</script>

<style lang="less" scoped>
.marketing-template-new {
    min-height: 100vh;
    background: #EB5912;
    padding-bottom: 80px;

    div {
        box-sizing: border-box;
    }

    img {
        width: 100%;
        vertical-align: top;
    }

    .headbg {
        position: relative;
        height: 600px;
        background: url(https://img.chaolu.com.cn/ACT/double11-2024/main/headbg2.png);
        background-size: 100% 100%;

        .top-button {
            position: absolute;
            right: 0;
            top: 280px;
            width: 88px;
            z-index: 99;

            .icon {
                width: 88px;
                height: 88px;
                object-fit: contain;
                margin-bottom: 32px;
            }

            .button {
                line-height: 56px;
                background: rgba(36, 40, 49, 0.3);
                border-radius: 40px 0 0 40px;
                color: #fff;
                width: 100%;
                text-align: center;
                margin-bottom: 32px;
                padding-left: 6px;
            }
        }
    }

    .goods {
        width: 686px;
        height: 621px;
        background-size: 100% 100%;
        margin: 20px auto 60px;
        position: relative;

        .sub {
            width: 536px;
            height: 124px;
            background: url(https://img.chaolu.com.cn/ACT/double11-2024/main/button1.png);
            background-size: 100% 100%;
            position: absolute;
            bottom: 33px;
            left: 50%;
            transform: translateX(-50%);
            font-size: 52px;
            color: #FFFFFF;
            text-align: center;
            line-height: 106px;

            &.dis {
                background-image: url(https://img.chaolu.com.cn/ACT/double11-2024/main/button2.png);
            }
        }
    }

    .pop-city-c {
        overflow: visible;
        padding-top: 200px;
        border-radius: 24px 24px 0px 0px;

        .icon {
            width: 273px;
            height: 260px;
            position: absolute;
            top: -100px;
            left: 50%;
            transform: translateX(-50%);
        }

        .tips {
            padding: 0px 100px 110px;
            font-size: 28px;
            line-height: 2;
            text-align: center;

            span {
                color: #F03C18;
                font-size: 36px;
                font-weight: bold;
                margin: 0 10px;
            }
        }

        .btn {
            padding: 26px 32px;
            text-align: center;
            line-height: 92px;
            height: 92px;
            font-size: 32px;
            display: flex;
            justify-content: space-between;
            align-items: center;
            box-shadow: 0px 0px 1px 0px rgba(0, 0, 0, 0.2);
            box-sizing: content-box;

            >div:first-child {
                border: 1px solid #EEEEEE;
                border-radius: 8px;
                width: 212px;
            }

            >div:last-child {
                background-color: #FFDE00;
                border: 1px solid #FFDE00;
                border-radius: 8px;
                width: 442px;
                font-weight: 600;
            }
        }
    }

    .pop-change {
        border-radius: 24px 24px 0px 0px;

        .p-title {
            font-size: 28px;
            line-height: 96px;
            height: 98px;
            border-bottom: 1px solid #EEEEEE;
            text-align: center;
            font-weight: 600;
        }

        .city-list {
            padding: 35px 32px 78px;

            p {
                padding: 0px 33px;
                font-size: 32px;
                background-color: #F5F5F5;
                border-radius: 8px;
                height: 128px;
                line-height: 128px;

                &.act {
                    border: 2px solid #232831;
                }
            }

            p+p {
                margin-top: 32px;
            }
        }

        .btn {
            box-shadow: 0px 0px 1px 0px rgba(0, 0, 0, 0.2);
            box-sizing: content-box;
            padding: 27px 32px;
            text-align: center;

            >p:first-child {
                font-size: 22px;
                color: #68413C;
                margin-bottom: 20px;
            }

            >p:last-child {
                background-color: #FFDE00;
                border: 1px solid #FFDE00;
                border-radius: 8px;
                height: 92px;
                font-weight: 600;
                line-height: 92px;
            }
        }
    }


    .pop-rule {
        border-radius: 24px 24px 0 0;
        color: #242831;

        .pop-rule-title {
            height: 140px;
            text-align: center;
            line-height: 130px;
            position: relative;
            background: linear-gradient(0deg, #FFFFFF 0%, #FFD6C0 100%);

            .pop-rule-close {
                position: absolute;
                right: 32px;
                top: 38px;
                width: 44px;
                height: 44px;
                line-height: 44px;
                border-radius: 50%;
                background: rgba(39, 40, 49, 0.2);
            }
        }

        .pop-rule-content {
            padding: 0 52px;
            max-height: 600px;
            overflow: auto;
            padding-bottom: 60px;
            white-space: pre-line;
        }
    }

    .pop-sure {
        width: 590px;
        border-radius: 12px;
        padding: 50px 40px;

        .sure-title {
            color: #242831;
            text-align: center;
        }

        .sure-content {
            color: #3C454E;
            line-height: 1.6;
            margin: 36px 0 48px;
            text-align: center;

            span {
                color: #F03C18;
            }
        }

        .sure-sub {
            border-radius: 8px;
            background: #FFDE00;
            color: #000;
            line-height: 96px;
            text-align: center;
        }
    }

    .pop-item {
        border-radius: 24px 24px 0 0;
        color: #242831;

        .buy-title {
            border-bottom: 1px solid #EEEEEE;
            height: 97px;
            line-height: 97px;
            padding: 0 32px;
            text-align: center;
            position: relative;
        }

        .buy-close {
            position: absolute;
            right: 32px;
            top: 50%;
            transform: translateY(-50%);

        }

        .cbox {
            padding: 37px 32px 0;
            border-bottom: 1px solid #eee
        }

        .buy-tips {
            color: #9AA1A9;
            margin: 4px 0 22px;
        }

        .goods-line {
            display: flex;
            margin: 22px 0 60px;

            .goods-img {
                width: 256px;

                img {
                    width: 100%;
                    border-radius: 16px;
                    display: block;
                }
            }

            .goods-info {
                flex: 1;
                margin-left: 30px;

                .goods-name {
                    color: #242831;
                }

                .time {
                    color: #242831;
                    margin: 12px 0 20px;
                    line-height: 1;
                }

                .labels {
                    margin: 8px 0 15px;

                    .label {
                        height: 30px;
                        background: linear-gradient(90deg, #FFE8B5 0%, #FFD7C3 100%);
                        padding: 0 13px;
                        margin: 0 12px 0 0;
                        color: #B12E1D;
                        border-radius: 4px;
                    }
                }

                .price-line {
                    margin-top: 8px;
                    line-height: 1;

                    .dl {
                        color: #EE4832;
                        margin-right: 4px;
                    }

                    .price {
                        color: #EE4832;
                        margin-right: 9px;
                        position: relative;
                        top: 2px;
                    }

                    .ori-price {
                        text-decoration: line-through;
                        color: #6C727A;
                    }
                }
            }
        }

        .city-list {
            padding: 0 0 32px;

            .city-model {
                margin-top: 24px;
                height: 128px;
                background: #F5F5F5;
                border-radius: 8px;
                padding: 0 32px;

                &.disabled {
                    color: #9AA1A9;
                }

                &.active {
                    border: 4px solid #242831;

                    .checkbox {
                        width: 48px;
                        height: 48px;
                        background: url(https://chaolucoachwebstatic.oss-cn-hangzhou.aliyuncs.com/static/img/spring2023/check.png);
                        background-size: 100% 100%;
                        border: none;
                        margin: 0;
                    }
                }

                .checkbox {
                    width: 32px;
                    height: 32px;
                    border: 1px solid #DDD;
                    border-radius: 50%;
                    margin-right: 8px;
                }

                .has-buy {
                    width: 102px;
                }
            }


        }

        .next {
            line-height: 96px;
            background: #FFDE00;
            border-radius: 8px;
            margin: 34px 32px 0;
            text-align: center;
            margin-bottom: 34px;

            &.grey {
                filter: grayscale(1);
            }
        }
    }

    .error-box {
        width: 630px;
        background: #ffffff;
        border-radius: 12px;
        margin: 0 auto;
        padding: 0 40px 48px;

        h4 {
            text-align: center;
            padding-top: 40px;
        }

        p {
            margin: 32px auto 56px;
            width: 510px;
            color: #3c454e;
            line-height: 44px;
        }

        .btn {
            margin: 0 auto;
            width: 510px;
            height: 96px;
            background: #ffde00;
            border-radius: 8px;
        }

        span {
            color: #f03c18;
        }
    }

    .red {
        color: #ee4832;
    }
}

/deep/ .red {
    color: #ff6e00;
}
</style>