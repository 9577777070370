<template>
  <div style="width: 100%;">
    <div class="select f30" @click="addAddress">
      <van-icon name="location" color="#A65123" class="lc" />
      <div v-if="addressCopy.addressDetail" class="pd">
        <div class="line1">{{ `${addressCopy.province} ${addressCopy.city} ${addressCopy.district}` }}</div>
        <div class="line2">{{ addressCopy.addressDetail }}</div>
        <div class="line3">{{ addressCopy.name }} <span class="phone">{{ addressCopy.phone }}</span> </div>
      </div>
      <span class="line4 fw6" v-else>请填写地址，用于物品配送</span>
      <van-icon v-show="!disabled" name="arrow" color="#A65123" />
    </div>
    <div v-show="!disabled" class="t1">请认真核对收货信息、收货地址，以确保物品可送达。</div>

    <van-popup v-model="show" position="bottom" safe-area-inset-bottom :class="['pop-address', focus ? 'focus' : '']" get-container="body" >
      <div class="pop-head">
        <span class="l1">新建地址</span>
        <van-icon name="cross" class="pop-close" color="#9AA1A9" @click="show = false" />
      </div>
      <div class="a-line">
        <div class="a-label">收货人</div>
        <div class="a-value">
          <input class="ip" type="text" @focus="inputFocus" @blur="inputBlur" v-model.trim="address.name" placeholder="姓名" />
        </div>
      </div>
      <div class="a-line">
        <div class="a-label">手机号</div>
        <div class="a-value">
          <input class="ip" type="text" @focus="inputFocus" @blur="inputBlur" v-model.trim="address.phone" placeholder="手机号码" maxlength="11" />
        </div>
      </div>
      <div class="a-line">
        <div class="a-label">所在地区</div>
        <div @click="areaShow = true" class="a-value">
          <div class="ip" :class="{ default: !address.district }">
            {{ address.district ? `${address.province} ${address.city} ${address.district}` : '省、市、区' }}
          </div>
          <van-icon name="arrow" class="right-logo" />
        </div>
      </div>
      <div class="a-line">
        <div class="a-label">详细地址</div>
        <div class="a-value">
          <input class="ip" type="text" @focus="inputFocus" @blur="inputBlur" v-model.trim="address.addressDetail" placeholder="街道、小区楼栋、门牌号等" maxlength="35" />
          <!-- <textarea v-model.trim="detail" placeholder="街道、小区楼栋、门牌号等" class="ip" maxlength="80" rows="1"></textarea> -->
        </div>
      </div>
      <div @click="setAddress" class="a-sub" :class="{ gray: !(address.name && address.phone&&address.phone.length == 11 && /^1\d{10}$/.test(address.phone) && address.addressDetail && address.district), }" :style="{ background: color }">确认</div>
      <van-popup v-model:show="areaShow" position="bottom">
        <van-area :area-list="addressData" @confirm="chooseAddress" @cancel="areaShow = false" />
      </van-popup>
    </van-popup>
  </div>
</template>
<script>
import addressData from '@/lib/address'
import userMixin from '@/mixin/userMixin'
import { appType } from '@/lib/appMethod'

export default {
  props: {
    color: {
      type: String,
      default: '',
    },
    disabled: {
      type: Boolean,
      default: false
    }
  },
  mixins: [userMixin],
  data() {
    return {
      show: false,
      areaShow: false,

      addressData,
      areaList: [],

      address: {
        addressDetail: '',
        city: '',
        district: '',
        name: '',
        phone: '',
        province: '',
        userId: ''
      },
      addressCopy: {},
      focus: false
    }
  },
  created() {
    this.getAddress()
  },
  methods: {
    inputFocus(v) {
      if (appType() === 'and') this.focus = true
    },
    inputBlur() {
      if (appType() === 'and') this.focus = false
    },
    getAddress() {console.log('g')
      this.$axios.post(this.baseURLApp + '/user/address/getByUserId', {
        userId: this.userId,
        token: this.token
      }).then((r) => {
        if (r.data) {
          this.address = r.data
          this.addressCopy = JSON.parse(JSON.stringify(r.data))
        }
        this.$emit('change', this.addressCopy)
      })
    },
    addAddress() {
      if (this.disabled) return
      this.address = JSON.parse(JSON.stringify(this.addressCopy))
      this.show = true
    },
    saveAddress() {console.log('s')
      return this.$axios.post(this.baseURLApp + '/user/address/addUserAddress', { ...this.address, userId: this.userId, token: this.token }).then((r) => {
        this.addressCopy = JSON.parse(JSON.stringify(this.address))
        this.$emit('change', this.addressCopy)
      }).catch(() => {

      })
    },
    chooseAddress(ret) {
      console.log(ret);
      this.areaList = ret;

      this.address.province = ret[0].name
      this.address.city = ret[1].name
      this.address.district = ret[2].name

      this.areaShow = false;
    },
    async setAddress() {
      console.log(this.address);
      if (!this.address.name) {
        return;
      }
      if (!/^1\d{10}$/.test(this.address.phone) || this.address.phone.length != 11) {
        return;
      }
      if (!this.address.district) {
        return;
      }
      if (!this.address.addressDetail) {
        return;
      }
      if (this.areaList.length) {
        this.address.city = this.areaList[1].name
        this.address.district = this.areaList[2].name
        this.address.province = this.areaList[0].name
      }


      this.show = false;
      await this.saveAddress()
      this.getAddress()
    }
  }
}
</script>
<style lang="less" scoped>
* {
  box-sizing: border-box;
}

.select {
  background: #F5F5F5;
  min-height: 128px;
  color: #242831;
  position: relative;
  display: flex;
  align-items: center;
  padding: 20px 28px;
  border-radius: 8px;
  box-sizing: border-box;
  .lc{
    margin-right: 16px;
  }
  .pd {
    font-size: 24px;
    line-height: 50px;
    flex: 1;
    .line2 {
      font-size: 30px;
      font-weight: 600;
    }

    .line3 {
      font-size: 26px;

      .phone {
        margin-left: 20px;
      }
    }
  }
  .line4 {
      color: #A65123;
      flex: 1;
    }
}

.t1 {
  color: #68413C;
  font-size: 22px;
  line-height: 2;
  margin: 16px 0 70px;
}

.pop-address {
  border-top-left-radius: 16px;
  border-top-right-radius: 16px;
  padding-left: 32px;
  padding-right: 32px;
  &.focus {
    padding-bottom: 600px;
  }
  .pop-head {
    height: 120px;
    display: flex;
    justify-content: space-between;
    align-items: center;

    .l1 {
      color: #242831;
      font-size: 32px;
      font-weight: bold;
    }

    .pop-close {
      font-size: 36px;
    }
  }

  .a-line {
    display: flex;
    margin-bottom: 44px;

    .a-label {
      flex-shrink: 0;
      width: 170px;
      color: #242831;
      font-size: 28px;
    }

    .a-value {
      flex: 1;
      border-bottom: 1px solid #EEEEEE;
      padding-bottom: 18px;
      position: relative;

      .ip {
        border: none;
        font-size: 28px;
      }

      .default {
        color: #ccc;
      }

      ::-webkit-input-placeholder {
        /* WebKit browsers */
        color: #ccc;
      }

      .right-logo {
        position: absolute;
        top: 4px;
        right: 0px;
      }
    }
  }

  .a-sub {
    margin-top: 300px;
    border-radius: 8px;
    line-height: 96px;
    color: #242831;
    font-size: 32px;
    background: linear-gradient(90deg, #FF7F37 0%, #FF3672 100%);
    text-align: center;
    margin-bottom: 24px;
  }

  .gray {
    opacity: 0.5;
    pointer-events: none;
  }
}
</style>
